import React, { useState } from 'react';

import { Icon } from '@chakra-ui/react';
import { MdDashboard, MdChat, MdExplore, MdOutlineMedication,MdLock,MdStore,MdPerson,MdOutlineHistoryToggleOff } from 'react-icons/md';
import { FaHandshake } from "react-icons/fa6";
import { HiLightBulb } from "react-icons/hi";
import { MdAccountCircle } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";


// Admin Imports
import DashboardsDefault from 'views/admin/dashboards/default';
import DashboardsRTLDefault from 'views/admin/dashboards/rtl';
import DashboardsCarInterface from 'views/admin/dashboards/carInterface';
import DashboardsSmartHome from 'views/admin/dashboards/smartHome';

//Property imports
import PropertiesOverview from 'views/admin/properties/overview';
import NewProperty from 'views/admin/properties/newProperty';
import EditProperty from 'views/admin/properties/editProperty';
import PropertyPriority from 'views/admin/properties/priority';

// NFT Imports
import NFTMarketplace from 'views/admin/nfts/marketplace';
import NFTPage from 'views/admin/nfts/page';
import NFTCollection from 'views/admin/nfts/collection';
import NFTProfile from 'views/admin/nfts/profile';

// Main Imports
import AccountBilling from 'views/admin/main/account/billing';
import AccountApplications from 'views/admin/main/account/application';
import AccountInvoice from 'views/admin/main/account/invoice';
import AccountSettings from 'views/admin/main/account/settings';
import AccountAllCourses from 'views/admin/main/account/courses';
import AccountCoursePage from 'views/admin/main/account/coursePage';

import UserNew from 'views/admin/main/users/newUser';
import UsersOverview from 'views/admin/main/users/overview';
import UsersReports from 'views/admin/main/users/reports';
import EditUser from 'views/admin/main/users/editUser';

import ProfileSettings from 'views/admin/main/profile/settings';
import ProfileOverview from 'views/admin/main/profile/overview';
import ProfileNewsfeed from 'views/admin/main/profile/newsfeed';

import ApplicationsKanban from 'views/admin/main/applications/kanban';
import ApplicationsDataTables from 'views/admin/main/applications/dataTables';
import ApplicationsCalendar from 'views/admin/main/applications/calendar';

import EcommerceNewProduct from 'views/admin/main/ecommerce/newProduct';
import EcommerceProductOverview from 'views/admin/main/ecommerce/overviewProduct';
import EcommerceProductSettings from 'views/admin/main/ecommerce/settingsProduct';
import EcommerceProductPage from 'views/admin/main/ecommerce/pageProduct';
import EcommerceOrderList from 'views/admin/main/ecommerce/orderList';
import EcommerceOrderDetails from 'views/admin/main/ecommerce/orderDetails';
import EcommerceReferrals from 'views/admin/main/ecommerce/referrals';

// Others
import OthersNotifications from 'views/admin/main/others/notifications';
import OthersPricing from 'views/admin/main/others/pricing';
import OthersError from 'views/admin/main/others/404';
import Messages from 'views/admin/main/others/messages';

// Auth Imports
import ForgotPasswordCentered from 'views/auth/forgotPassword/ForgotPasswordCentered.jsx';
import ForgotPasswordDefault from 'views/auth/forgotPassword/ForgotPasswordDefault.jsx';
import LockCentered from 'views/auth/lock/LockCentered.jsx';
import LockDefault from 'views/auth/lock/LockDefault.jsx';
import SignInCentered from 'views/auth/signIn/SignInCentered.jsx';
import SignInDefault from 'views/auth/signIn/SignInDefault.jsx';
import SignUpCentered from 'views/auth/signUp/SignUpCentered.jsx';
import SignUpDefault from 'views/auth/signUp/SignUpDefault.jsx';
import VerificationCentered from 'views/auth/verification/VerificationCentered.jsx';
import VerificationDefault from 'views/auth/verification/VerificationDefault.jsx';

import DeveloperOverview from 'views/admin/main/developers/overview';
import DeveloperNew from 'views/admin/main/developers/newDeveloper';
import EditDeveloper from 'views/admin/main/developers/editDeveloper';

import PropertyTypesOverview from 'views/admin/main/property-types/overview';
import PropertyTypeNew from 'views/admin/main/property-types/newPropertyType';
import EditPropertyType from 'views/admin/main/property-types/editPropertyType';

import StandoutAmenitiesOverview from 'views/admin/main/standout-amenities/overview';
import StandoutAmenitiesNew from 'views/admin/main/standout-amenities/newStandoutAmenities';
import EditStandoutAmenities from 'views/admin/main/standout-amenities/editStandoutAmenities';

import DoctorOverview from 'views/admin/doctors/overview';
import DoctorNew from 'views/admin/doctors/newDoctor';
import DoctorEdit from 'views/admin/doctors/editDoctor';

import PatientOverview from 'views/admin/patients/overview';
import PatientNew from 'views/admin/patients/newPatient';
import PatientEdit from 'views/admin/patients/editPatient';
import PatientMedicalRecords from 'views/admin/patients/medicalRecord/overview';
import EditPatientMedicalRecords from 'views/admin/patients/medicalRecord/edit';
import NewPatientMedicalRecords from 'views/admin/patients/medicalRecord/new';

import BrokerageOverview from 'views/admin/brokerage/overview';
import BrokerageNew from 'views/admin/brokerage/new';
import EditBrokerage from 'views/admin/brokerage/edit';

import OwnerOverview from 'views/admin/owner/overview';
import OwnerNew from 'views/admin/owner/new';
import EditOwner from 'views/admin/owner/edit';

import BusinessOverview from 'views/admin/business/overview';
import BusinessNew from 'views/admin/business/new';
import EditBusiness from 'views/admin/business/edit';

import StoryOverview from 'views/admin/story/overview';

import MedicalRecordNew from 'views/admin/medicalRecords/new';

import HospitalNew from 'views/admin/hospital/new';
import HospitalOverview from 'views/admin/hospital/overview';
import HospitalEdit from 'views/admin/hospital/edit';

import axios from 'axios';
import { API_BASE_URL } from 'constants/apiConstants';

// var homeItems = [];
// axios.get(API_BASE_URL+'get/sidebar',{
//   headers: {
//     Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
//   }
// })
// .then((response) => {
//   console.log(response.data.data)
//   var result = response.data.data;
//   for (let userObject of result) {
//     if(userObject.category === 'Home'){
//       var component1111 = <DashboardsDefault />;
//       homeItems.push(
//         {
//           name: userObject.name,
//           layout: '/admin',
//           path: userObject.path,
//           component:component1111,
//         }
//       );
//     }
//   }
// })
// .catch((error) => {
//   console.log(error)
// })

const pathArray = window.location.pathname.split("/");
const segment_2 = pathArray[2];
if(segment_2 === 'health-records'){
  var routes = [
    // --- Dashboards ---
    {
      name: 'Home',
      path: '/health-records/dashboards',
      icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Health Management Settings',
          layout: '/admin',
          path: '/health-records/dashboards/default',
          exact: false,
          component: <DashboardsDefault />,
        },
        {
          name: 'Notifications',
          layout: '/admin',
          path: '/health-records/notifications',
          exact: false,
        },
        {
          name: 'New Stories',
          layout: '/admin',
          path: '/health-records/story/new',
          exact: false,
        },
        {
          name: 'Hospital /Clinics',
          layout: '/admin',
          path: '/health-records/hospitals',
          exact: false,
        },
        {
          name: 'Health Records',
          layout: '/admin',
          path: '/health-records/health/records',
          exact: false,
        },
        {
          name: 'Ads',
          layout: '/admin',
          path: '/health-records/ads',
          exact: false,
        },
        {
          name: 'Posts',
          layout: '/admin',
          path: '/health-records/posts',
          exact: false,
        },
        {
          name: 'Medical Content',
          layout: '/admin',
          path: '/health-records/medical-content',
          exact: false,
        },
      ],
    },
  
    {
      name: 'Chat',
      path: '/properties',
      icon: <Icon as={MdChat} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Search',
          layout: '/admin',
          path: '/health-records/search',
          exact: false,
        },
        {
          name: 'Inbox',
          layout: '/admin',
          path: '/health-records/chat/inbox',
          exact: false,
        },
        {
          name: 'New Chat',
          layout: '/admin',
          path: '/health-records/chat/new',
          exact: false,
        },
        {
          name: 'Sent',
          layout: '/admin',
          path: '/health-records/chat/sent',
          exact: false,
        },
        {
          name: 'Chat Notifications',
          layout: '/admin',
          path: '/health-records/chat/notifications',
          exact: false,
        }
      ],
    },
  
    {
      name: 'Explore',
      path: '/property-types/211',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'All',
          layout: '/admin',
          path: '/health-records/explore/all',
          exact: false,
        },
        {
          name: 'My Reports',
          layout: '/admin',
          path: '/health-records/explore/my-reports',
          exact: false,
        },
        {
          name: 'My Health',
          path: '/health-records/dashboards/212 ',
          collapse: true,
          items: [
            {
              name: 'Search',
              layout: '/admin',
              path: '/dashboards/explore/my-health',
              exact: false,
            },
            {
              name: 'Medical Records',
              layout: '/admin',
              path: '/dashboards/explore/medical-records',
              exact: false,
              component: <MedicalRecordNew />,
            },
            {
              name: 'Medications',
              layout: '/admin',
              path: '/dashboards/explore/medications',
              exact: false,
            },
            {
              name: 'Vaccines',
              layout: '/admin',
              path: '/dashboards/explore/vaccines',
              exact: false,
            },
            {
              name: 'Allergies',
              layout: '/admin',
              path: '/dashboards/explore/allergies',
              exact: false,
            },
            {
              name: 'Surgeries',
              layout: '/admin',
              path: '/dashboards/explore/surgeries',
              exact: false,
            },
          ],
        },
        {
          name: 'Doctors',
          layout: '/admin',
          path: '/health-records/explore/doctors',
          exact: false,
        },
        {
          name: 'Stories',
          layout: '/admin',
          path: '/health-records/explore/stories',
          exact: false,
        },
        {
          name: 'Hospitals',
          layout: '/admin',
          path: '/health-records/explore/hospitals',
          exact: false,
        },
        {
          name: 'Clinics',
          layout: '/admin',
          path: '/health-records/explore/clinics',
          exact: false,
        },
        {
          name: 'Business',
          layout: '/admin',
          path: '/health-records/explore/business',
          exact: false,
        },
        {
          name: 'Specialities',
          path: '/health-records/dashboards/213',
          collapse: true,
          items: [
            {
              name: 'General Medice',
              layout: '/admin',
              path: '/health-records/specialist/general-medice',
              exact: false,
            },
            {
              name: 'Internal Medicine',
              layout: '/admin',
              path: '/health-records/specialist/internal-medicine',
              exact: false,
            },
            {
              name: 'Cardiology',
              layout: '/admin',
              path: '/health-records/specialist/cardiology',
              exact: false,
            },
            {
              name: 'Orthopaedic',
              layout: '/admin',
              path: '/health-records/specialist/orthopaedic',
              exact: false,
            },
            {
              name: 'Rheumatology',
              layout: '/admin',
              path: '/health-records/specialist/rheumatology',
              exact: false,
            },
            {
              name: 'Diet & Nutrition',
              layout: '/admin',
              path: '/health-records/specialist/diet-nutrition',
              exact: false,
            },

            {
              name: 'OB & Gynaecology',
              layout: '/admin',
              path: '/health-records/specialist/ob-gynaecology',
              exact: false,
            },
            {
              name: 'Fetal Medice',
              layout: '/admin',
              path: '/health-records/specialist/fetal-medice',
              exact: false,
            },
            {
              name: 'Neonatology',
              layout: '/admin',
              path: '/health-records/specialist/neonatology',
              exact: false,
            },
            {
              name: 'Paediatrics',
              layout: '/admin',
              path: '/health-records/specialist/paediatrics',
              exact: false,
            },
            {
              name: 'IVF & Fertility',
              layout: '/admin',
              path: '/health-records/specialist/ivf-fertility',
              exact: false,
            },
            {
              name: 'Dentistry',
              layout: '/admin',
              path: '/health-records/specialist/dentistry',
              exact: false,
            },
            {
              name: 'Dermatology',
              layout: '/admin',
              path: '/health-records/specialist/dermatology',
              exact: false,
            },
            {
              name: 'Gastroenterology',
              layout: '/admin',
              path: '/health-records/specialist/gastroenterology',
              exact: false,
            },
            {
              name: 'Neurology',
              layout: '/admin',
              path: '/health-records/specialist/neurology',
              exact: false,
            },
            {
              name: 'Pulmonology',
              layout: '/admin',
              path: '/health-records/specialist/pulmonology',
              exact: false,
            },
            {
              name: 'Urology',
              layout: '/admin',
              path: '/health-records/specialist/urology',
              exact: false,
            },

            {
              name: 'Ophthalmology',
              layout: '/admin',
              path: '/health-records/specialist/ophthalmology',
              exact: false,
            },
            {
              name: 'Nephrology',
              layout: '/admin',
              path: '/health-records/specialist/nephrology',
              exact: false,
            },
            {
              name: 'ENT',
              layout: '/admin',
              path: '/health-records/specialist/ent',
              exact: false,
            },
            {
              name: 'Family Medicine',
              layout: '/admin',
              path: '/health-records/specialist/family-medicine',
              exact: false,
            },
            {
              name: 'Endocrinology',
              layout: '/admin',
              path: '/health-records/specialist/endocrinology',
              exact: false,
            },
            {
              name: 'Physiotherapy',
              layout: '/admin',
              path: '/health-records/specialist/physiotherapy',
              exact: false,
            },
            {
              name: 'General Surgery',
              layout: '/admin',
              path: '/health-records/specialist/general-surgery',
              exact: false,
            },
            {
              name: 'Bariatric Surgery',
              layout: '/admin',
              path: '/health-records/specialist/bariatric-surgery',
              exact: false,
            },
            {
              name: 'Plastic Surgery',
              layout: '/admin',
              path: '/health-records/specialist/plastic-surgery',
              exact: false,
            }
          ],
        }
      ],
    },

    {
      name: 'My Health',
      path: '/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Search',
          layout: '/admin',
          path: '/health-records/my-health/default',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Medical Records',
          layout: '/admin',
          path: '/health-records/medical-record/new',
          exact: false,
          component: <MedicalRecordNew />,
        },
        {
          name: 'Medications',
          layout: '/admin',
          path: '/health-records/my-health/medications',
          exact: false,
        },
        {
          name: 'Vaccines',
          layout: '/admin',
          path: '/health-records/my-health/vaccines',
          exact: false,
        },
        {
          name: 'Allergies',
          layout: '/admin',
          path: '/health-records/my-health/allergies',
          exact: false,
        },
        {
          name: 'Surgeries',
          layout: '/admin',
          path: '/health-records/my-health/surgeries',
          exact: false,
        }
      ],
    },

    {
      name: 'e-consult',
      path: '/health-records/dashboards/default',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
      ],
    },
  
    {
      name: 'Patient profile',
      path: '/health-records/standout-amenities',
      icon: <Icon as={MdOutlineMedication} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Patient Overview',
          layout: '/admin',
          path: '/health-records/patient-profile/new',
          exact: false,
          component: <PatientNew />,
        },
        {
          name: 'Edit Information',
          layout: '/admin',
          path: '/health-records/patient-profile/overview',
          exact: false,
          component: <PatientOverview />,
        },
      ],
    },
    
    {
      name: 'Appointments',
      path: '/health-records/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'New Appointment',
          layout: '/admin',
          path: '/health-records/appointments/new',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Appointment Overview',
          layout: '/admin',
          path: '/health-records/appointments/overview',
          exact: false,
        },
        {
          name: 'Upcoming Appointment',
          layout: '/admin',
          path: '/health-records/appointments/upcoming',
          exact: false,
        },
        {
          name: 'Reschedule Appointment',
          layout: '/admin',
          path: '/health-records/appointments/reschedule',
          exact: false,
        },
        {
          name: 'Cancel Appointment',
          layout: '/admin',
          path: '/health-records/appointments/cancel',
          exact: false,
        },
      ],
    },

    {
      name: 'Women+',
      path: '/health-records/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Menstrual+',
          layout: '/admin',
          path: '/health-records/women/menstrual',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Pregnancy Tracking',
          layout: '/admin',
          path: '/health-records/women/pregnancy-tracking',
          exact: false,
        },
        {
          name: 'Menopause',
          layout: '/admin',
          path: '/health-records/women/menopause',
          exact: false,
        }
      ],
    },

    {
      name: 'Track your health',
      path: '/health-records/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Sync your Device',
          layout: '/admin',
          path: '/dashboards/track-health/sync-device',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Activity Tracker',
          layout: '/admin',
          path: '/dashboards/track-health/activity-tracker',
          exact: false,
        },
        {
          name: 'Heart Rate Monitor',
          layout: '/admin',
          path: '/dashboards/track-health/heart-rate-monitor',
          exact: false,
        },
        {
          name: 'Sleep',
          layout: '/admin',
          path: '/dashboards/track-health/sleep',
          exact: false,
        },
        {
          name: 'Water Intake',
          layout: '/admin',
          path: '/dashboards/track-health/water-intake',
          exact: false,
        },
        {
          name: 'Nutri+',
          layout: '/admin',
          path: '/dashboards/track-health/nutri',
          exact: false,
        },
        {
          name: 'Fitness',
          layout: '/admin',
          path: '/dashboards/track-health/fitness',
          exact: false,
        },
        {
          name: 'Recipes',
          layout: '/admin',
          path: '/dashboards/track-health/recipes',
          exact: false,
        },
        {
          name: 'BMI Calculator',
          layout: '/admin',
          path: '/dashboards/track-health/bmi-calculator',
          exact: false,
        },
        {
          name: 'Insulin Check',
          layout: '/admin',
          path: '/dashboards/track-health/insulin-check',
          exact: false,
        },
        {
          name: 'Stroke Calculator',
          layout: '/admin',
          path: '/dashboards/track-health/stroke-calculator',
          exact: false,
        },
        {
          name: 'BP Check',
          layout: '/admin',
          path: '/dashboards/track-health/bp-check',
          exact: false,
        }
      ],
    },

    {
      name: 'Health forum',
      path: '/health-records/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Blogs',
          layout: '/admin',
          path: '/health-records/health-forum/blogs',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Mindfulness',
          layout: '/admin',
          path: '/health-records/health-forum/mindfulness',
          exact: false,
        },
        {
          name: 'Tips',
          layout: '/admin',
          path: '/health-records/health-forum/tips',
          exact: false,
        }
      ],
    },

    // --- Authentication ---
    {
      name: 'Authentication',
      path: '/health-records/auth',
      icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        // --- Sign In ---
        {
          name: 'Sign In',
          path: '/sign-in',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/sign-in/default',
              component: <SignInDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/sign-in/centered',
              component: <SignInCentered />,
            },
          ],
        },
        // --- Sign Up ---
        {
          name: 'Sign Up',
          path: '/sign-up',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/sign-up/default',
              component: <SignUpDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/sign-up/centered',
              component: <SignUpCentered />,
            },
          ],
        },
        // --- Verification ---
        {
          name: 'Verification',
          path: '/verification',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/verification/default',
              component: <VerificationDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/verification/centered',
              component: <VerificationCentered />,
            },
          ],
        },
        // --- Lock ---
        {
          name: 'Lock',
          path: '/lock',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/lock/default',
              component: <LockDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/lock/centered',
              component: <LockCentered />,
            },
          ],
        },
        // --- Forgot Password ---
        {
          name: 'Forgot Password',
          path: '/forgot-password',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/forgot-password/default',
              component: <ForgotPasswordDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/forgot-password/centered',
              component: <ForgotPasswordCentered />,
            },
          ],
        },
      ],
    },
  ];
}else if(segment_2 === 'hospital-management'){
  var routes = [
    // --- Dashboards ---
    {
      name: 'Home',
      path: '/hospital-management/dashboards',
      icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Hospital Management Settings',
          layout: '/admin',
          path: '/hospital-management/dashboards/default',
          component: <DashboardsDefault />,
        },
        {
          name: 'New Hospital',
          layout: '/admin',
          path: '/hospital-management/hospital/new',
          component: <HospitalNew />,
        },
        {
          name: 'Hospital Overview',
          layout: '/admin',
          path: '/hospital-management/hospital/overview',
          component: <HospitalOverview />,
        },
        {
          layout: '/admin',
          path: '/hospital-management/hospital/edit/:id',
          exact: false,
          component: <HospitalEdit />,
        },
      ],
    },
  
    {
      name: 'Patient Type',
      path: '/hospital-management/patient',
      icon: <Icon as={MdChat} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Walk In',
          path: '/hospital-management/1',
          collapse: true,
          items: [
            {
              name: 'ID Check',
              layout: '/admin',
              path: '/hospital-management/2',
              exact: false,
            },
            {
              name: 'Insurance Check',
              layout: '/admin',
              path: '/hospital-management/3',
              exact: false,
            },
            {
              name: 'Mode of Payment',
              layout: '/admin',
              path: '/hospital-management/4',
              exact: false,
            }
          ],
        },
        {
          name: 'New patient',
          path: '/hospital-management/patient',
          collapse: true,
          items: [
            {
              name: 'New Patient',
              layout: '/admin',
              path: '/hospital-management/patient/new',
              exact: false,
              component: <PatientNew />,
            },
            {
              name: 'Patient Overview',
              layout: '/admin',
              path: '/hospital-management/patient/overview',
              exact: false,
              component: <PatientOverview />,
            },
            {
              name: 'ID Check',
              layout: '/admin',
              path: '/hospital-management/5',
              exact: false,
            },
            {
              name: 'Insurance Check',
              layout: '/admin',
              path: '/hospital-management/6',
              exact: false,
            },
            {
              name: 'Appointment Check',
              layout: '/admin',
              path: '/hospital-management/7',
              exact: false,
            },
            {
              name: 'Registration',
              layout: '/admin',
              path: '/hospital-management/8',
              exact: false,
            },
            {
              name: 'Mode of Payment',
              layout: '/admin',
              path: '/hospital-management/9',
              exact: false,
            }
          ],
        },
        {
          name: 'Pre-registered',
          path: '/hospital-management/pre-registered/overview',
          collapse: true,
          items: [
            {
              name: 'ID Check',
              layout: '/admin',
              path: '/hospital-management/10',
              exact: false,
            },
            {
              name: 'Insurance Check',
              layout: '/admin',
              path: '/hospital-management/11',
              exact: false,
            },
            {
              name: 'Appointment Check',
              layout: '/admin',
              path: '/hospital-management/12',
              exact: false,
            },
            {
              name: 'Mode of Payment',
              layout: '/admin',
              path: '/hospital-management/13',
              exact: false,
            }
          ],
        },
        {
          name: 'Patient Database',
          layout: '/admin',
          path: '/hospital-management/14',
          exact: false,
          //component: <EditPatient />
        },
        {
          name: 'Patient Admission / Discharge',
          layout: '/admin',
          path: '/hospital-management/15',
          exact: false,
          //component: <EditPatient />
        },
        {
          name: 'Appointment Overview',
          layout: '/admin',
          path: '/hospital-management/16',
          exact: false,
        },
        {
          name: 'Manage Appointment',
          path: '/hospital-management/17',
          collapse: true,
          items: [
            {
              name: 'Schedule',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Reschedule',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Confirmed',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Cancelled',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
            {
              name: 'Reminders',
              layout: '/admin',
              path: '/dashboards/default',
              exact: false,
            },
          ],
        },
        {
          name: 'Appointment Status',
          path: '/hospital-management/18',
          collapse: true,
          items: [
            {
              name: 'No Show',
              layout: '/admin',
              path: '/hospital-management/19',
              exact: false,
            },
            {
              name: 'Cancelled',
              layout: '/admin',
              path: '/hospital-management/20',
              exact: false,
            },
            {
              name: 'Rescheduled',
              layout: '/admin',
              path: '/hospital-management/21',
              exact: false,
            }
          ],
        },
        {
          layout: '/admin',
          path: '/hospital-management/patient/edit/:id',
          exact: false,
          component: <PatientEdit />,
        },
      ],
    },  
  
    {
      name: 'Nurse',
      path: '/hospital-management/agents',
      icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Patients',
          path: '/hospital-management/patient/334',
          collapse: true,
          items: [
            {
              name: 'Allergy Pop-Up',
              layout: '/admin',
              path: '/hospital-management/dashboards/333',
              exact: false,
            }
          ],
        },
        {
          name: 'Triage',
          path: '/hospital-management/patient/215',
          collapse: true,
          items: [
            {
              name: 'Level 1',
              layout: '/admin',
              path: '/hospital-management/22',
              exact: false,
              //component: <PropertyTypeNew />,
            },
            {
              name: 'Level 2',
              layout: '/admin',
              path: '/hospital-management/23',
              exact: false,
              component: <MedicalRecordNew />,
            },
            {
              name: 'Level 3',
              layout: '/admin',
              path: '/hospital-management/24',
              exact: false,
            },
            {
              name: 'Level 4',
              layout: '/admin',
              path: '/hospital-management/25',
              exact: false,
            },
            {
              name: 'Level 5',
              layout: '/admin',
              path: '/hospital-management/26',
              exact: false,
            }
          ],
        },
        {
          name: 'Vitals',
          path: '/hospital-management/dashboards/214',
          collapse: true,
          items: [
            {
              name: 'Height',
              layout: '/admin',
              path: '/hospital-management/27',
              exact: false,
              //component: <PropertyTypeNew />,
            },
            {
              name: 'Weight',
              layout: '/admin',
              path: '/hospital-management/28',
              exact: false,
              component: <MedicalRecordNew />,
            },
            {
              name: 'BMI',
              layout: '/admin',
              path: '/hospital-management/dashboards/29',
              exact: false,
            },
            {
              name: 'BP',
              layout: '/admin',
              path: '/hospital-management/dashboards/30',
              exact: false,
            },
            {
              name: 'Pain Score',
              layout: '/admin',
              path: '/hospital-management/dashboards/31',
              exact: false,
            },
            {
              name: 'Heart Rate',
              layout: '/admin',
              path: '/hospital-management/dashboards/32',
              exact: false,
            }
          ],
        },
        {
          name: 'Nurse Notes',
          layout: '/admin',
          path: '/hospital-management/dashboards/33',
          exact: false,
        },
        {
          name: 'Critical Alterts',
          layout: '/admin',
          path: '/hospital-management/dashboards/34',
          exact: false,
        },
        {
          name: 'Procedures',
          layout: '/admin',
          path: '/hospital-management/dashboards/35',
          exact: false,
        },
        {
          name: 'Tasks',
          layout: '/admin',
          path: '/hospital-management/dashboards/36',
          exact: false,
        },
        {
          name: 'Reports',
          layout: '/admin',
          path: '/hospital-management/dashboards/37',
          exact: false,
        },
        {
          name: 'Follow Ups',
          layout: '/admin',
          path: '/hospital-management/dashboards/38',
          exact: false,
        }
      ],
    },
  
    {
      name: 'Doctor',
      path: '/hospital-management/216',
      icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'New Doctor',
          layout: '/admin',
          path: '/hospital-management/doctor/new',
          exact: false,
          component: <DoctorNew />,
        },
        {
          name: 'Doctor Overview',
          layout: '/admin',
          path: '/hospital-management/doctor/overview',
          exact: false,
          component: <DoctorOverview />,
        },
        {
          layout: '/admin',
          path: '/hospital-management/doctor/edit/:id',
          exact: false,
          component: <DoctorEdit />,
        },
        {
          name: 'Patients',
          path: '/hospital-management/dashboards/335',
          collapse: true,
          items: [
            {
              name: 'Allergy Pop-Up',
              layout: '/admin',
              path: '/hospital-management/dashboards/39',
              exact: false,
            },
          ]
        },
        {
          name: 'Appointments',
          layout: '/admin',
          path: '/hospital-management/dashboards/40',
          exact: false,
        },
        {
          name: 'Critical Alerts',
          layout: '/admin',
          path: '/hospital-management/dashboards/41',
          exact: false,
        },
        {
          name: 'Consultations',
          path: '/hospital-management/dashboards/42',
          collapse: true,
          items: [
            {
              name: 'Patient History',
              layout: '/admin',
              path: '/hospital-management/dashboards/43',
              exact: false,
            },
            {
              name: 'Diagnosis',
              layout: '/admin',
              path: '/hospital-management/dashboards/44',
              exact: false,
            },
            {
              name: 'Treatment Plan',
              layout: '/admin',
              path: '/hospital-management/dashboards/45',
              exact: false,
            },
            {
              name: 'Prompter Sync',
              layout: '/admin',
              path: '/hospital-management/dashboards/46',
              exact: false,
            }
          ]
        },
        {
          name: 'Notes',
          path: '/hospital-management/dashboards/217',
          collapse: true,
          items: [
            {
              name: 'Orders',
              layout: '/admin',
              path: '/hospital-management/dashboards/47',
              exact: false,
            },
            {
              name: 'Lab',
              layout: '/admin',
              path: '/hospital-management/dashboards/48',
              exact: false,
            },
            {
              name: 'Radiology',
              layout: '/admin',
              path: '/hospital-management/dashboards/49',
              exact: false,
            },
            {
              name: 'Insurance Limit',
              layout: '/admin',
              path: '/hospital-management/dashboards/50',
              exact: false,
            },
            {
              name: 'Physiotherapy',
              layout: '/admin',
              path: '/hospital-management/dashboards/51',
              exact: false,
            }
          ]
        },
        {
          name: 'Prescriptions',
          path: '/hospital-management/dashboards/52',
          collapse: true,
          items: [
            {
              name: 'DRG Codes',
              layout: '/admin',
              path: '/hospital-management/dashboards/53',
              exact: false,
            },
            {
              name: 'CPT Codes',
              layout: '/admin',
              path: '/hospital-management/dashboards/54',
              exact: false,
            },
            {
              name: 'Medication',
              layout: '/admin',
              path: '/hospital-management/dashboards/55',
              exact: false,
            }
          ]
        },
        {
          name: 'Reminders & Notifications',
          layout: '/admin',
          path: '/hospital-management/dashboards/56',
          exact: false,
        },
        {
          name: 'Reports',
          layout: '/admin',
          path: '/hospital-management/dashboards/57',
          exact: false,
        },
        {
          name: 'Pending Reports',
          layout: '/admin',
          path: '/hospital-management/dashboards/58',
          exact: false,
        },
      ],
    },
  
    {
      name: 'Insurance',
      path: '/hospital-management/developers',
      icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Coverage, Approvals, Claims, Submission',
          layout: '/admin',
          path: '/hospital-management/dashboards/59',
          exact: false,
        },
        {
          name: 'Coding & File Audit',
          layout: '/admin',
          path: '/hospital-management/dashboards/60',
          exact: false,
        },
        {
          name: 'Insurance Verification',
          layout: '/admin',
          path: '/hospital-management/dashboards/61',
          exact: false,
        },
        {
          name: 'Payer Contracts',
          layout: '/admin',
          path: '/hospital-management/dashboards/62',
          exact: false,
        },
        {
          name: 'Reports',
          layout: '/admin',
          path: '/hospital-management/dashboards/63',
          exact: false,
        }
      ],
    },
  
    {
      name: 'Billing',
      path: 'brokerages',
      icon: <Icon as={FaHandshake} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Patients',
          layout: '/admin',
          path: '/hospital-management/dashboards/64',
          exact: false,
        },
        {
          name: 'Invoice',
          layout: '/admin',
          path: '/hospital-management/dashboards/65',
          exact: false,
        },
        {
          name: 'Payments',
          layout: '/admin',
          path: '/hospital-management/dashboards/66',
          exact: false,
        },
        {
          name: 'DRG Calculator',
          layout: '/admin',
          path: '/hospital-management/dashboards/67',
          exact: false,
        },
        {
          name: 'ICD - 10 Codes',
          layout: '/admin',
          path: '/hospital-management/dashboards/68',
          exact: false,
        },
        {
          name: 'CPT Codes',
          layout: '/admin',
          path: '/hospital-management/dashboards/69',
          exact: false,
        },
        {
          name: 'Reports',
          layout: '/admin',
          path: '/hospital-management/dashboards/70',
          exact: false,
        }
      ],
    },

    {
      name: 'Finance',
      path: '/hospital-management/owner',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Revenue',
          layout: '/admin',
          path: '/hospital-management/dashboards/71',
          exact: false,
        },
        {
          name: 'Expenses',
          layout: '/admin',
          path: '/hospital-management/dashboards/72',
          exact: false,
        },
        {
          name: 'Budgeting',
          layout: '/admin',
          path: '/hospital-management/dashboards/73',
          exact: false,
        },
        {
          name: 'Profit',
          layout: '/admin',
          path: '/hospital-management/dashboards/74',
          exact: false,
        },
        {
          name: 'Audits',
          layout: '/admin',
          path: '/hospital-management/dashboards/75',
          exact: false,
        },
        {
          name: 'Tax Compliance',
          layout: '/admin',
          path: '/hospital-management/dashboards/76',
          exact: false,
        },
        {
          name: 'Reports',
          layout: '/admin',
          path: '/hospital-management/dashboards/77',
          exact: false,
        }
      ],
    },

    {
      name: 'Call Centre',
      path: '/hospital-management/219',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'New Agent',
          layout: '/admin',
          path: '/hospital-management/dashboards/78',
          exact: false,
        },
        {
          name: 'Agent Overview',
          layout: '/admin',
          path: '/hospital-management/dashboards/79',
          exact: false,
        },
        {
          name: 'Recorded Calls',
          layout: '/admin',
          path: '/hospital-management/dashboards/80',
          exact: false,
        },
        {
          name: 'Call Management',
          layout: '/admin',
          path: '/hospital-management/dashboards/81',
          exact: false,
        },
        {
          name: 'Performance',
          layout: '/admin',
          path: '/hospital-management/dashboards/82',
          exact: false,
        },
        {
          name: 'Reports',
          layout: '/admin',
          path: '/hospital-management/dashboards/83',
          exact: false,
        },
        {
          name: 'Appointments',
          path: '/hospital-management/dashboards/220',
          collapse: true,
          items: [
            {
              name: 'New Appointment',
              layout: '/admin',
              path: '/hospital-management/dashboards/84',
              exact: false,
            },
            {
              name: 'Appointment Overview',
              layout: '/admin',
              path: '/hospital-management/dashboards/85',
              exact: false,
            },
            {
              name: 'Manage Appointment',
              layout: '/admin',
              path: '/hospital-management/dashboards/86',
              exact: false,
            },
            {
              name: 'Appointment Status',
              layout: '/admin',
              path: '/hospital-management/dashboards/87',
              exact: false,
            },
            {
              name: 'Follow - Ups',
              layout: '/admin',
              path: '/hospital-management/dashboards/88',
              exact: false,
            },
            {
              name: 'Reminders',
              layout: '/admin',
              path: '/hospital-management/dashboards/90',
              exact: false,
            }
          ]
        }
      ],
    },

    {
      name: 'e-consult Appointments',
      path: '/hospital-management/221',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Appointment Overview',
          layout: '/admin',
          path: '/hospital-management/dashboards/91',
          exact: false,
        },
        {
          name: 'Manage Appointment',
          path: '/hospital-management/dashboards/92',
          collapse: true,
          items: [
            {
              name: 'Schedule',
              layout: '/admin',
              path: '/hospital-management/dashboards/default',
              exact: false,
            },
            {
              name: 'Reschedule',
              layout: '/admin',
              path: '/hospital-management/dashboards/default',
              exact: false,
            },
            {
              name: 'Confirmed',
              layout: '/admin',
              path: '/hospital-management/dashboards/default',
              exact: false,
            },
            {
              name: 'Cancelled',
              layout: '/admin',
              path: '/hospital-management/dashboards/default',
              exact: false,
            },
            {
              name: 'Reminders',
              layout: '/admin',
              path: '/hospital-management/dashboards/default',
              exact: false,
            }
          ]
        },
        {
          name: 'Appointment Status',
          path: '/hospital-management/dashboards/222',
          collapse: true,
          items: [
            {
              name: 'No Show',
              layout: '/admin',
              path: '/hospital-management/dashboards/93',
              exact: false,
            },
            {
              name: 'Cancelled',
              layout: '/admin',
              path: '/hospital-management/dashboards/94',
              exact: false,
            },
            {
              name: 'Rescheduled',
              layout: '/admin',
              path: '/hospital-management/dashboards/95',
              exact: false,
            }
          ]
        }
      ],
    },
  
    {
      name: 'Pharmacy',
      path: '/hospital-management/223',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Patients',
          layout: '/admin',
          path: '/hospital-management/dashboards/95',
          exact: false,
        },
        {
          name: 'Prescriptions',
          layout: '/admin',
          path: '/hospital-management/dashboards/96',
          exact: false,
        },
        {
          name: 'Approvals',
          layout: '/admin',
          path: '/hospital-management/dashboards/97',
          exact: false,
        },
        {
          name: 'Inventory',
          layout: '/admin',
          path: '/hospital-management/dashboards/98',
          exact: false,
        },
        {
          name: 'Reports',
          layout: '/admin',
          path: '/hospital-management/dashboards/99',
          exact: false,
        },
        {
          name: 'Codes',
          layout: '/admin',
          path: '/hospital-management/dashboards/100',
          exact: false,
        }
      ],
    },

    {
      name: 'HR',
      path: '/hospital-management/224',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Staff Management',
          layout: '/admin',
          path: '/hospital-management/dashboards/101',
          exact: false,
        },
        {
          name: 'Employee Records',
          layout: '/admin',
          path: '/hospital-management/dashboards/102',
          exact: false,
        },
        {
          name: 'Payroll',
          layout: '/admin',
          path: '/hospital-management/dashboards/103',
          exact: false,
        },
        {
          name: 'Compliance',
          layout: '/admin',
          path: '/hospital-management/dashboards/104',
          exact: false,
        },
        {
          name: 'Labor Complaints & PRO Portal',
          layout: '/admin',
          path: '/hospital-management/dashboards/105',
          exact: false,
        },
        {
          name: 'Reports',
          layout: '/admin',
          path: '/hospital-management/dashboards/106',
          exact: false,
        }
      ],
    },

    {
      name: 'Procurement',
      path: '/hospital-management/225',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Inventory Management',
          layout: '/admin',
          path: '/hospital-management/dashboards/107',
          exact: false,
        },
        {
          name: 'Supplier  Management',
          layout: '/admin',
          path: '/hospital-management/dashboards/108',
          exact: false,
        },
        {
          name: 'Reports',
          layout: '/admin',
          path: '/hospital-management/dashboards/109',
          exact: false,
        }
      ],
    },

    {
      name: 'Home Healthcare',
      path: '/hospital-management/110',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
      ],
    },

    {
      name: 'Management+',
      path: '/hospital-management/111',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
      ],
    },

    {
      name: 'Reports',
      path: '/hospital-management/112',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
      ],
    },

    {
      name: 'Facility',
      path: '/hospital-management/113',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
      ],
    },

    {
      name: 'Provider Admin',
      path: '/hospital-management/226',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'User Management',
          layout: '/admin',
          path: '/hospital-management/dashboards/114',
          exact: false,
        },
        {
          name: 'Patient Management',
          layout: '/admin',
          path: '/hospital-management/dashboards/115',
          exact: false,
        },
        {
          name: 'Doctor Management',
          layout: '/admin',
          path: '/hospital-management/dashboards/116',
          exact: false,
        },
        {
          name: 'Staff Management',
          layout: '/admin',
          path: '/hospital-management/dashboards/117',
          exact: false,
        },
        {
          name: 'Inventory Management',
          layout: '/admin',
          path: '/hospital-management/dashboards/118',
          exact: false,
        },
        {
          name: 'Lab Management',
          layout: '/admin',
          path: '/hospital-management/dashboards/119',
          exact: false,
        },
        {
          name: 'Pharmacy Management',
          layout: '/admin',
          path: '/hospital-management/dashboards/120',
          exact: false,
        },
        {
          name: 'Finance Management',
          layout: '/admin',
          path: '/hospital-management/dashboards/130',
          exact: false,
        },
        {
          name: 'Reports & Analytics',
          layout: '/admin',
          path: '/hospital-management/dashboards/131',
          exact: false,
        },
        {
          name: 'Integrations',
          layout: '/admin',
          path: '/hospital-management/dashboards/132',
          exact: false,
        },
        {
          name: 'Data Security & Privacy',
          layout: '/admin',
          path: '/hospital-management/dashboards/133',
          exact: false,
        },
        {
          name: 'Notifications',
          layout: '/admin',
          path: '/hospital-management/dashboards/134',
          exact: false,
        },
        {
          name: 'Accreditation',
          layout: '/admin',
          path: '/hospital-management/dashboards/135',
          exact: false,
        },
        {
          name: 'Maintenance',
          layout: '/admin',
          path: '/hospital-management/dashboards/136',
          exact: false,
        },
        {
          name: 'Quality & Saftety',
          layout: '/admin',
          path: '/hospital-management/dashboards/137',
          exact: false,
        }
      ],
    },

    {
      name: 'Midwives',
      path: '/hospital-management/227',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Patients',
          path: '/hospital-management/dashboards/138',
          collapse: true,
          items: [
            {
              name: 'Allergy Pop - Up',
              layout: '/admin',
              path: '/hospital-management/dashboards/default',
              exact: false,
            },
            {
              name: 'Trimester',
              layout: '/admin',
              path: '/hospital-management/dashboards/default',
              exact: false,
            },
            {
              name: 'Manage Appointment',
              layout: '/admin',
              path: '/hospital-management/dashboards/default',
              exact: false,
            },
            {
              name: 'Appointment Status',
              layout: '/admin',
              path: '/hospital-management/dashboards/default',
              exact: false,
            },
            {
              name: 'Follow - Ups',
              layout: '/admin',
              path: '/hospital-management/dashboards/default',
              exact: false,
            },
            {
              name: 'Reminders',
              layout: '/admin',
              path: '/hospital-management/dashboards/default',
              exact: false,
            },
          ]
        },
        {
          name: 'Notes',
          layout: '/admin',
          path: '/hospital-management/dashboards/139',
          exact: false,
        },
        {
          name: 'Newborn Information',
          layout: '/admin',
          path: '/hospital-management/dashboards/140',
          exact: false,
        },
        {
          name: 'Critical Alerts ',
          layout: '/admin',
          path: '/hospital-management/dashboards/141',
          exact: false,
        }
      ],
    },

    {
      name: 'Departments',
      path: '/hospital-management/228',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'General Medice',
          layout: '/admin',
          path: '/hospital-management/dashboards/142',
          exact: false,
        },
        {
          name: 'Internal Medicine',
          layout: '/admin',
          path: '/hospital-management/dashboards/143',
          exact: false,
        },
        {
          name: 'Cardiology',
          layout: '/admin',
          path: '/hospital-management/dashboards/144',
          exact: false,
        },
        {
          name: 'Orthopaedic',
          layout: '/admin',
          path: '/hospital-management/dashboards/145',
          exact: false,
        },
        {
          name: 'Rheumatology',
          layout: '/admin',
          path: '/hospital-management/dashboards/146',
          exact: false,
        },
        {
          name: 'Diet & Nutrition',
          layout: '/admin',
          path: '/hospital-management/dashboards/147',
          exact: false,
        },
        {
          name: 'OB & Gynaecology',
          layout: '/admin',
          path: '/hospital-management/dashboards/148',
          exact: false,
        },
        {
          name: 'Fetal Medice',
          layout: '/admin',
          path: '/hospital-management/dashboards/149',
          exact: false,
        },
        {
          name: 'Neonatology',
          layout: '/admin',
          path: '/hospital-management/dashboards/150',
          exact: false,
        },
        {
          name: 'Paediatrics',
          layout: '/admin',
          path: '/hospital-management/dashboards/151',
          exact: false,
        },
        {
          name: 'IVF & Fertility',
          layout: '/admin',
          path: '/hospital-management/dashboards/152',
          exact: false,
        },
        {
          name: 'Dentistry',
          layout: '/admin',
          path: '/hospital-management/dashboards/153',
          exact: false,
        },
        {
          name: 'Dermatology',
          layout: '/admin',
          path: '/hospital-management/dashboards/154',
          exact: false,
        },
        {
          name: ' Gastroenterology',
          layout: '/admin',
          path: '/hospital-management/dashboards/155',
          exact: false,
        },
        {
          name: 'Neurology',
          layout: '/admin',
          path: '/hospital-management/dashboards/156',
          exact: false,
        },
        {
          name: 'Pulmonology',
          layout: '/admin',
          path: '/hospital-management/dashboards/157',
          exact: false,
        },
        {
          name: 'Urology',
          layout: '/admin',
          path: '/hospital-management/dashboards/158',
          exact: false,
        },
        {
          name: 'Ophthalmology',
          layout: '/admin',
          path: '/hospital-management/dashboards/159',
          exact: false,
        },
        {
          name: 'Nephrology',
          layout: '/admin',
          path: '/hospital-management/dashboards/160',
          exact: false,
        },
        {
          name: 'ENT',
          layout: '/admin',
          path: '/hospital-management/dashboards/161',
          exact: false,
        },
        {
          name: 'Vascular',
          layout: '/admin',
          path: '/hospital-management/dashboards/162',
          exact: false,
        },
        {
          name: 'Family Medicine',
          layout: '/admin',
          path: '/hospital-management/dashboards/163',
          exact: false,
        },
        {
          name: 'Endocrinology',
          layout: '/admin',
          path: '/hospital-management/dashboards/164',
          exact: false,
        },
        {
          name: 'Physiotherapy',
          layout: '/admin',
          path: '/hospital-management/dashboards/165',
          exact: false,
        },
        {
          name: 'General Surgery',
          layout: '/admin',
          path: '/hospital-management/dashboards/166',
          exact: false,
        },
        {
          name: 'Bariatric Surgery',
          layout: '/admin',
          path: '/hospital-management/dashboards/167',
          exact: false,
        },
        {
          name: 'Plastic Surgery',
          layout: '/admin',
          path: '/hospital-management/dashboards/168',
          exact: false,
        }
      ],
    },

    {
      name: 'Radiology',
      path: '/hospital-management/229',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
      ],
    },

    {
      name: 'Lab',
      path: '/hospital-management/300',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
      ],
    },

    {
      name: 'Settings',
      path: '/hospital-management/301',
      icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
      ],
    },
  
    // --- Authentication ---
    {
      name: 'Authentication',
      path: '/hospital-management/auth',
      icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        // --- Sign In ---
        {
          name: 'Sign In',
          path: '/hospital-management/sign-in',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/hospital-management/sign-in/default',
              component: <SignInDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/hospital-management/sign-in/centered',
              component: <SignInCentered />,
            },
          ],
        },
        // --- Sign Up ---
        {
          name: 'Sign Up',
          path: '/hospital-management/sign-up',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/hospital-management/sign-up/default',
              component: <SignUpDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/hospital-management/sign-up/centered',
              component: <SignUpCentered />,
            },
          ],
        },
        // --- Verification ---
        {
          name: 'Verification',
          path: '/hospital-management/verification',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/hospital-management/verification/default',
              component: <VerificationDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/hospital-management/verification/centered',
              component: <VerificationCentered />,
            },
          ],
        },
        // --- Lock ---
        {
          name: 'Lock',
          path: '/hospital-management/lock',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/hospital-management/lock/default',
              component: <LockDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/hospital-management/lock/centered',
              component: <LockCentered />,
            },
          ],
        },
        // --- Forgot Password ---
        {
          name: 'Forgot Password',
          path: '/hospital-management/forgot-password',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/hospital-management/forgot-password/default',
              component: <ForgotPasswordDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/hospital-management/forgot-password/centered',
              component: <ForgotPasswordCentered />,
            },
          ],
        },

        { 
          layout: '/admin',
          path: '/hospital-management/patient/medical-records/:id',
          exact: false,
          component: <PatientMedicalRecords />,
        },

        {
          layout: '/admin',
          path: '/hospital-management/patient/medical-records/:pmid/edit/:id',
          exact: false,
          component: <EditPatientMedicalRecords />,
        },

        {
          layout: '/admin',
          path: '/hospital-management/patient/medical-records/new/:id',
          exact: false,
          component: <NewPatientMedicalRecords />,
        },
      ],
    },
  ];
}else if(segment_2 === 'medical-tourism'){
  var routes = [
    // --- Dashboards ---
    {
      name: 'Home',
      path: '/medical-tourism/301',
      icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Medical Tourism Settings',
          layout: '/admin',
          path: '/medical-tourism/dashboards/1',
          component: <DashboardsDefault />,
        },
      ],
    },
  
    // --- Properties ---
    {
      name: 'Research & Destination Selection',
      path: '/medical-tourism/302',
      icon: <Icon as={MdChat} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Destination',
          path: '/medical-tourism/dashboards/303',
          collapse: true,
          items: [
            {
              name: 'Countries',
              layout: '/admin',
              path: '/medical-tourism/dashboards/2',
              exact: false,
            }
          ]
        }
      ],
    },
  
    {
      name: 'Reason of Visit/ Treatments',
      path: '/medical-tourism/303',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Text box',
          layout: '/admin',
          path: '/medical-tourism/dashboards/3',
          exact: false,
        }
      ],
    },
  
    {
      name: 'Provider',
      path: '/medical-tourism/standout-amenities',
      icon: <Icon as={MdOutlineMedication} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Hospitals',
          path: '/medical-tourism/dashboards/4',
          collapse: true,
          items: [
            {
              name: 'Specialities',
              layout: '/admin',
              path: '/medical-tourism/dashboards/5',
              exact: false,
            },
            {
              name: 'Doctors',
              layout: '/admin',
              path: '/medical-tourism/dashboards/6',
              exact: false,
            },
            {
              name: 'Packages',
              layout: '/admin',
              path: '/medical-tourism/dashboards/7',
              exact: false,
            },
          ]
        },
        {
          name: 'Clinics',
          path: '/medical-tourism/dashboards/304',
          collapse: true,
          items: [
            {
              name: 'Specialities',
              layout: '/admin',
              path: '/medical-tourism/dashboards/8',
              exact: false,
            },
            {
              name: 'Doctors',
              layout: '/admin',
              path: '/medical-tourism/dashboards/9',
              exact: false,
            },
            {
              name: 'Packages',
              layout: '/admin',
              path: '/medical-tourism/dashboards/10',
              exact: false,
            },
          ]
        },
        {
          name: 'Wellness Center',
          path: '/medical-tourism/dashboards/305',
          collapse: true,
          items: [
            {
              name: 'Services',
              layout: '/admin',
              path: '/medical-tourism/dashboards/11',
              exact: false,
            }
          ]
        }
      ],
    },

    {
      name: 'E-Consultation',
      path: '/medical-tourism/307',
      icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Payment',
          path: '/medical-tourism/dashboards/308',
          collapse: true,
          items: [
            {
              name: 'Cash',
              layout: '/admin',
              path: '/medical-tourism/dashboards/12',
              exact: false,
            },
            {
              name: 'Review',
              layout: '/admin',
              path: '/medical-tourism/dashboards/13',
              exact: false,
            }
          ]
        }
      ],
    },
  
    {
      name: 'Travel Concierge',
      path: '/medical-tourism/309',
      icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Visa Services- Medical Visa',
          layout: '/admin',
          path: '/medical-tourism/dashboards/14',
          exact: false,
        },
        {
          name: 'Airlines',
          path: '/medical-tourism/dashboards/15',
          collapse: true,
          items: [
            {
              name: 'Normal',
              layout: '/admin',
              path: '/medical-tourism/dashboards/310',
              exact: false,
            },
            {
              name: 'Private Jets',
              layout: '/admin',
              path: '/medical-tourism/dashboards/311',
              exact: false,
            },
            {
              name: 'Air Ambulance',
              layout: '/admin',
              path: '/medical-tourism/dashboards/312',
              exact: false,
            },
          ]
        },
        {
          name: 'Airport',
          path: '/medical-tourism/dashboards/16',
          collapse: true,
          items: [
            {
              name: 'Ground Ambulance',
              layout: '/admin',
              path: '/medical-tourism/dashboards/312',
              exact: false,
            },
            {
              name: 'Transportation Cars (Driver)',
              layout: '/admin',
              path: '/medical-tourism/dashboards/313',
              exact: false,
            },
            {
              name: 'Currency Exchange',
              layout: '/admin',
              path: '/medical-tourism/dashboards/314',
              exact: false,
            },
            {
              name: 'Card Sim',
              layout: '/admin',
              path: '/medical-tourism/dashboards/315',
              exact: false,
            },
            {
              name: 'Translator',
              layout: '/admin',
              path: '/medical-tourism/dashboards/316',
              exact: false,
            },
            {
              name: 'POC',
              layout: '/admin',
              path: '/medical-tourism/dashboards/317',
              exact: false,
            },
          ]
        },
        {
          name: 'Hotels',
          layout: '/admin',
          path: '/medical-tourism/dashboards/17',
          exact: false,
        },
        {
          name: 'Home Healthcare',
          layout: '/admin',
          path: '/medical-tourism/dashboards/18',
          exact: false,
        },
      ],
    },

    {
      name: 'Tourist Health',
      path: '/medical-tourism/318',
      icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Doctor-on-call',
          layout: '/admin',
          path: '/medical-tourism/dashboards/19',
          exact: false,
        },
        {
          name: 'Home Healthcare',
          layout: '/admin',
          path: '/medical-tourism/dashboards/20',
        },
      ],
    },
    
    // --- Authentication ---
    {
      name: 'Authentication',
      path: '/medical-tourism/auth',
      icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        // --- Sign In ---
        {
          name: 'Sign In',
          path: '/medical-tourism/sign-in',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/medical-tourism/sign-in/default',
              component: <SignInDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/medical-tourism/sign-in/centered',
              component: <SignInCentered />,
            },
          ],
        },
        // --- Sign Up ---
        {
          name: 'Sign Up',
          path: '/sign-up',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/medical-tourism/sign-up/default',
              component: <SignUpDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/medical-tourism/sign-up/centered',
              component: <SignUpCentered />,
            },
          ],
        },
        // --- Verification ---
        {
          name: 'Verification',
          path: '/medical-tourism/verification',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/medical-tourism/verification/default',
              component: <VerificationDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/medical-tourism/verification/centered',
              component: <VerificationCentered />,
            },
          ],
        },
        // --- Lock ---
        {
          name: 'Lock',
          path: '/medical-tourism/lock',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/medical-tourism/lock/default',
              component: <LockDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/medical-tourism/lock/centered',
              component: <LockCentered />,
            },
          ],
        },
        // --- Forgot Password ---
        {
          name: 'Forgot Password',
          path: '/medical-tourism/forgot-password',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/medical-tourism/forgot-password/default',
              component: <ForgotPasswordDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/medical-tourism/forgot-password/centered',
              component: <ForgotPasswordCentered />,
            },
          ],
        },
      ],
    },
  ];
}else{
  var routes = [
    // --- Dashboards ---
    {
      name: 'Home',
      path: '/health-records/dashboards',
      icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Hospital Management Settings',
          layout: '/admin',
          path: '/health-records/dashboards/default',
          exact: false,
          component: <DashboardsDefault />,
        },
        {
          name: 'Notifications',
          layout: '/admin',
          path: '/health-records/notifications',
          exact: false,
        },
        {
          name: 'New Stories',
          layout: '/admin',
          path: '/health-records/story/new',
          exact: false,
        },
        {
          name: 'Hospital /Clinics',
          layout: '/admin',
          path: '/health-records/hospitals',
          exact: false,
        },
        {
          name: 'Health Records',
          layout: '/admin',
          path: '/health-records/health/records',
          exact: false,
        },
        {
          name: 'Ads',
          layout: '/admin',
          path: '/health-records/ads',
          exact: false,
        },
        {
          name: 'Posts',
          layout: '/admin',
          path: '/health-records/posts',
          exact: false,
        },
        {
          name: 'Medical Content',
          layout: '/admin',
          path: '/health-records/medical-content',
          exact: false,
        },
      ],
    },
  
    {
      name: 'Chat',
      path: '/properties',
      icon: <Icon as={MdChat} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Search',
          layout: '/admin',
          path: '/health-records/search',
          exact: false,
        },
        {
          name: 'Inbox',
          layout: '/admin',
          path: '/health-records/chat/inbox',
          exact: false,
        },
        {
          name: 'New Chat',
          layout: '/admin',
          path: '/health-records/chat/new',
          exact: false,
        },
        {
          name: 'Sent',
          layout: '/admin',
          path: '/health-records/chat/sent',
          exact: false,
        },
        {
          name: 'Chat Notifications',
          layout: '/admin',
          path: '/health-records/chat/notifications',
          exact: false,
        }
      ],
    },
  
    {
      name: 'Explore',
      path: '/property-types/211',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'All',
          layout: '/admin',
          path: '/health-records/explore/all',
          exact: false,
        },
        {
          name: 'My Reports',
          layout: '/admin',
          path: '/health-records/explore/my-reports',
          exact: false,
        },
        {
          name: 'My Health',
          path: '/health-records/dashboards/212 ',
          collapse: true,
          items: [
            {
              name: 'Search',
              layout: '/admin',
              path: '/dashboards/explore/my-health',
              exact: false,
            },
            {
              name: 'Medical Records',
              layout: '/admin',
              path: '/dashboards/explore/medical-records',
              exact: false,
              component: <MedicalRecordNew />,
            },
            {
              name: 'Medications',
              layout: '/admin',
              path: '/dashboards/explore/medications',
              exact: false,
            },
            {
              name: 'Vaccines',
              layout: '/admin',
              path: '/dashboards/explore/vaccines',
              exact: false,
            },
            {
              name: 'Allergies',
              layout: '/admin',
              path: '/dashboards/explore/allergies',
              exact: false,
            },
            {
              name: 'Surgeries',
              layout: '/admin',
              path: '/dashboards/explore/surgeries',
              exact: false,
            },
          ],
        },
        {
          name: 'Doctors',
          layout: '/admin',
          path: '/health-records/explore/doctors',
          exact: false,
        },
        {
          name: 'Stories',
          layout: '/admin',
          path: '/health-records/explore/stories',
          exact: false,
        },
        {
          name: 'Hospitals',
          layout: '/admin',
          path: '/health-records/explore/hospitals',
          exact: false,
        },
        {
          name: 'Clinics',
          layout: '/admin',
          path: '/health-records/explore/clinics',
          exact: false,
        },
        {
          name: 'Business',
          layout: '/admin',
          path: '/health-records/explore/business',
          exact: false,
        },
        {
          name: 'Specialities',
          path: '/health-records/dashboards/213',
          collapse: true,
          items: [
            {
              name: 'General Medice',
              layout: '/admin',
              path: '/health-records/specialist/general-medice',
              exact: false,
            },
            {
              name: 'Internal Medicine',
              layout: '/admin',
              path: '/health-records/specialist/internal-medicine',
              exact: false,
            },
            {
              name: 'Cardiology',
              layout: '/admin',
              path: '/health-records/specialist/cardiology',
              exact: false,
            },
            {
              name: 'Orthopaedic',
              layout: '/admin',
              path: '/health-records/specialist/orthopaedic',
              exact: false,
            },
            {
              name: 'Rheumatology',
              layout: '/admin',
              path: '/health-records/specialist/rheumatology',
              exact: false,
            },
            {
              name: 'Diet & Nutrition',
              layout: '/admin',
              path: '/health-records/specialist/diet-nutrition',
              exact: false,
            },

            {
              name: 'OB & Gynaecology',
              layout: '/admin',
              path: '/health-records/specialist/ob-gynaecology',
              exact: false,
            },
            {
              name: 'Fetal Medice',
              layout: '/admin',
              path: '/health-records/specialist/fetal-medice',
              exact: false,
            },
            {
              name: 'Neonatology',
              layout: '/admin',
              path: '/health-records/specialist/neonatology',
              exact: false,
            },
            {
              name: 'Paediatrics',
              layout: '/admin',
              path: '/health-records/specialist/paediatrics',
              exact: false,
            },
            {
              name: 'IVF & Fertility',
              layout: '/admin',
              path: '/health-records/specialist/ivf-fertility',
              exact: false,
            },
            {
              name: 'Dentistry',
              layout: '/admin',
              path: '/health-records/specialist/dentistry',
              exact: false,
            },
            {
              name: 'Dermatology',
              layout: '/admin',
              path: '/health-records/specialist/dermatology',
              exact: false,
            },
            {
              name: 'Gastroenterology',
              layout: '/admin',
              path: '/health-records/specialist/gastroenterology',
              exact: false,
            },
            {
              name: 'Neurology',
              layout: '/admin',
              path: '/health-records/specialist/neurology',
              exact: false,
            },
            {
              name: 'Pulmonology',
              layout: '/admin',
              path: '/health-records/specialist/pulmonology',
              exact: false,
            },
            {
              name: 'Urology',
              layout: '/admin',
              path: '/health-records/specialist/urology',
              exact: false,
            },

            {
              name: 'Ophthalmology',
              layout: '/admin',
              path: '/health-records/specialist/ophthalmology',
              exact: false,
            },
            {
              name: 'Nephrology',
              layout: '/admin',
              path: '/health-records/specialist/nephrology',
              exact: false,
            },
            {
              name: 'ENT',
              layout: '/admin',
              path: '/health-records/specialist/ent',
              exact: false,
            },
            {
              name: 'Family Medicine',
              layout: '/admin',
              path: '/health-records/specialist/family-medicine',
              exact: false,
            },
            {
              name: 'Endocrinology',
              layout: '/admin',
              path: '/health-records/specialist/endocrinology',
              exact: false,
            },
            {
              name: 'Physiotherapy',
              layout: '/admin',
              path: '/health-records/specialist/physiotherapy',
              exact: false,
            },
            {
              name: 'General Surgery',
              layout: '/admin',
              path: '/health-records/specialist/general-surgery',
              exact: false,
            },
            {
              name: 'Bariatric Surgery',
              layout: '/admin',
              path: '/health-records/specialist/bariatric-surgery',
              exact: false,
            },
            {
              name: 'Plastic Surgery',
              layout: '/admin',
              path: '/health-records/specialist/plastic-surgery',
              exact: false,
            }
          ],
        }
      ],
    },

    {
      name: 'My Health',
      path: '/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Search',
          layout: '/admin',
          path: '/health-records/my-health/default',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Medical Records',
          layout: '/admin',
          path: '/health-records/medical-record/new',
          exact: false,
          component: <MedicalRecordNew />,
        },
        {
          name: 'Medications',
          layout: '/admin',
          path: '/health-records/my-health/medications',
          exact: false,
        },
        {
          name: 'Vaccines',
          layout: '/admin',
          path: '/health-records/my-health/vaccines',
          exact: false,
        },
        {
          name: 'Allergies',
          layout: '/admin',
          path: '/health-records/my-health/allergies',
          exact: false,
        },
        {
          name: 'Surgeries',
          layout: '/admin',
          path: '/health-records/my-health/surgeries',
          exact: false,
        }
      ],
    },

    {
      name: 'e-consult',
      path: '/health-records/dashboards/default',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
      ],
    },
  
    {
      name: 'Patient profile',
      path: '/health-records/standout-amenities',
      icon: <Icon as={MdOutlineMedication} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Patient Overview',
          layout: '/admin',
          path: '/health-records/patient-profile/new',
          exact: false,
          component: <PatientNew />,
        },
        {
          name: 'Edit Information',
          layout: '/admin',
          path: '/health-records/patient-profile/overview',
          exact: false,
          component: <PatientOverview />,
        },
      ],
    },
    
    {
      name: 'Appointments',
      path: '/health-records/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'New Appointment',
          layout: '/admin',
          path: '/health-records/appointments/new',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Appointment Overview',
          layout: '/admin',
          path: '/health-records/appointments/overview',
          exact: false,
        },
        {
          name: 'Upcoming Appointment',
          layout: '/admin',
          path: '/health-records/appointments/upcoming',
          exact: false,
        },
        {
          name: 'Reschedule Appointment',
          layout: '/admin',
          path: '/health-records/appointments/reschedule',
          exact: false,
        },
        {
          name: 'Cancel Appointment',
          layout: '/admin',
          path: '/health-records/appointments/cancel',
          exact: false,
        },
      ],
    },

    {
      name: 'Women+',
      path: '/health-records/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Menstrual+',
          layout: '/admin',
          path: '/health-records/women/menstrual',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Pregnancy Tracking',
          layout: '/admin',
          path: '/health-records/women/pregnancy-tracking',
          exact: false,
        },
        {
          name: 'Menopause',
          layout: '/admin',
          path: '/health-records/women/menopause',
          exact: false,
        }
      ],
    },

    {
      name: 'Track your health',
      path: '/health-records/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Sync your Device',
          layout: '/admin',
          path: '/dashboards/track-health/sync-device',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Activity Tracker',
          layout: '/admin',
          path: '/dashboards/track-health/activity-tracker',
          exact: false,
        },
        {
          name: 'Heart Rate Monitor',
          layout: '/admin',
          path: '/dashboards/track-health/heart-rate-monitor',
          exact: false,
        },
        {
          name: 'Sleep',
          layout: '/admin',
          path: '/dashboards/track-health/sleep',
          exact: false,
        },
        {
          name: 'Water Intake',
          layout: '/admin',
          path: '/dashboards/track-health/water-intake',
          exact: false,
        },
        {
          name: 'Nutri+',
          layout: '/admin',
          path: '/dashboards/track-health/nutri',
          exact: false,
        },
        {
          name: 'Fitness',
          layout: '/admin',
          path: '/dashboards/track-health/fitness',
          exact: false,
        },
        {
          name: 'Recipes',
          layout: '/admin',
          path: '/dashboards/track-health/recipes',
          exact: false,
        },
        {
          name: 'BMI Calculator',
          layout: '/admin',
          path: '/dashboards/track-health/bmi-calculator',
          exact: false,
        },
        {
          name: 'Insulin Check',
          layout: '/admin',
          path: '/dashboards/track-health/insulin-check',
          exact: false,
        },
        {
          name: 'Stroke Calculator',
          layout: '/admin',
          path: '/dashboards/track-health/stroke-calculator',
          exact: false,
        },
        {
          name: 'BP Check',
          layout: '/admin',
          path: '/dashboards/track-health/bp-check',
          exact: false,
        }
      ],
    },

    {
      name: 'Health forum',
      path: '/health-records/property-types',
      icon: <Icon as={MdExplore} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Blogs',
          layout: '/admin',
          path: '/health-records/health-forum/blogs',
          exact: false,
          //component: <PropertyTypeNew />,
        },
        {
          name: 'Mindfulness',
          layout: '/admin',
          path: '/health-records/health-forum/mindfulness',
          exact: false,
        },
        {
          name: 'Tips',
          layout: '/admin',
          path: '/health-records/health-forum/tips',
          exact: false,
        }
      ],
    },

    // --- Authentication ---
    {
      name: 'Authentication',
      path: '/health-records/auth',
      icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        // --- Sign In ---
        {
          name: 'Sign In',
          path: '/sign-in',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/sign-in/default',
              component: <SignInDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/sign-in/centered',
              component: <SignInCentered />,
            },
          ],
        },
        // --- Sign Up ---
        {
          name: 'Sign Up',
          path: '/sign-up',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/sign-up/default',
              component: <SignUpDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/sign-up/centered',
              component: <SignUpCentered />,
            },
          ],
        },
        // --- Verification ---
        {
          name: 'Verification',
          path: '/verification',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/verification/default',
              component: <VerificationDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/verification/centered',
              component: <VerificationCentered />,
            },
          ],
        },
        // --- Lock ---
        {
          name: 'Lock',
          path: '/lock',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/lock/default',
              component: <LockDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/lock/centered',
              component: <LockCentered />,
            },
          ],
        },
        // --- Forgot Password ---
        {
          name: 'Forgot Password',
          path: '/forgot-password',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/forgot-password/default',
              component: <ForgotPasswordDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/forgot-password/centered',
              component: <ForgotPasswordCentered />,
            },
          ],
        },
      ],
    },
  ];
}

export default routes;