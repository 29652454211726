import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
    FormLabel,
    useToast,
    RadioGroup,
    Radio
  } from '@chakra-ui/react';
  // Custom components
  
  import Card from 'components/card/Card';
  import InputField from 'components/fields/InputField';
  import React, { useState,useEffect } from 'react';
  import { useNavigate } from 'react-router-dom';
  import Dropzone from 'react-dropzone';
  import Multiselect from 'multiselect-react-dropdown';
  import axios from 'axios';
  import { API_BASE_URL } from 'constants/apiConstants';
  import TextField from 'components/fields/TextField';
  import { useParams } from 'react-router-dom';
  import { ASSETS_URL } from 'constants/apiConstants';
  
  export default function New() {
    const navigate = useNavigate();
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [medicalConditions,setMedicalConditions] =useState([]);
    const [activeBullets, setActiveBullets] = useState({
      user: true,
      educationalInformation: false,
      professionalInformation: false,
      publicationResearch: false,
      accessibility: false,
    });
    
    const [input,setInput] = useState({
        hospital_name: "",
        patient_id: "",
        blood_group: "",
        doctor: "",
        gender: "",
        weight: "",
        height: "",
        medical_conditions_ids: "",
        experience_title: "",
        experience_description: "",
        patient_name: "",
        patient_address: "",
        patient_mobile_number: "",
        patient_email: "",
        patient_pay_type: "",
        insurance_provider: "",
        policy_number: "",
        network: "",
        coverage: "",
        allergies: "",
        surgeries: "",
        x_rays: "",
        last_vital_weight: "",
        last_vital_height: "",
        last_vital_blood_presure: "",
        last_vital_pulse: "",
        aspirin: "",
        nurofen: "",
        paracetamol: "",
        lab_report_wbc: "",
        lab_report_neu: "",
        lab_report_lym: "",
        lab_report_mono: "",
    });
    const [errors, setErrors] = useState({});
    const toast = useToast();
    const [medicalRecordId, setMedicalRecordId] = useState('');
    const [gender, setGender] = useState('');
    const { id } = useParams();
    const [medicalFiles,setMedicalFiles] = useState([]);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setInput({
        ...input,
        [name]: value,
      });
    };
  
    const handleGenderChange = (value) => {
      setGender(value);
    };

    const getMedicalConditions = () => {
        axios.get(API_BASE_URL+'medical/conditions',{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
        })
        .then((response) => {
            setMedicalConditions(response.data.data)
        })
        .catch((error) => {
          toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
    }

    const handleMedicalConditionsSelectValue = (value) => {
        input.medical_conditions_ids = [];
        value.forEach(function(v) {
          input.medical_conditions_ids.push(v.id);
        });
    };
  
    const validateForm = (data) => {
      const errors = {};
      
      if (!data.hospital_name) {
        errors.hospital_name = 'This field is required';
      }
      
      if (!data.blood_group) {
        errors.blood_group = 'This field is required';
      }
  
      if (!data.doctor) {
        errors.doctor = 'This field is required';
      }
  
      if (!data.weight) {
        errors.weight = 'This field is required';
      }

      if (!data.height) {
        errors.height = 'This field is required';
      }

      if (!data.medical_conditions_ids.length) {
        errors.medical_conditions_ids = 'This field is required';
      }

      if (!gender) {
        errors.gender = 'This field is required';
      }
      return errors;
    };
  
    const handleSubmitStep1Info = (e) => {
      e.preventDefault();
      const newErrors = validateForm(input);
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        const payload = {
          "hospital_name":input.hospital_name,
          "patient_id":id,
          "blood_group":input.blood_group,
          "doctor":input.doctor,
          "gender":gender,
          "weight":input.weight,
          "height":input.height,
          "medical_conditions":input.medical_conditions_ids,
          "medicalRecordId":medicalRecordId
        };

        axios.post(API_BASE_URL+'medical-record/store',payload,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
        })
        .then(function (response){
          if(response.data.status === true){
            setMedicalRecordId(response.data.data.medicalId);
            toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
            educationalInformationTab.current.click();
          }else if(response.data.status === false){
            toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
          }
        })
        .catch(function (error){
          toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
      }
    };
  
    const validateStep2InformationForm = (data) => {
      const errors = {};
      
      if (!data.experience_title) {
        errors.experience_title = 'This field is required';
      }
  
      if (!data.experience_description) {
        errors.experience_description = 'This field is required';
      }
      
      if (!data.patient_name) {
        errors.patient_name = 'This field is required';
      }
  
      if (!data.patient_address) {
        errors.patient_address = 'This field is required';
      }
  
      if (!data.patient_mobile_number) {
        errors.patient_mobile_number = 'This field is required';
      }

      if (!data.patient_email) {
        errors.patient_email = 'This field is required';
      }

      if (!data.patient_pay_type) {
        errors.patient_pay_type = 'This field is required';
      }
      return errors;
    };
  
    const handleSubmitStep2Info = (e) => {
      e.preventDefault();
      if(medicalRecordId === ''){
        toast({ title: 'Please fill Step 1 details first.',status: 'error',isClosable: true,position: 'top'})
        return false;
      }
  
      const newErrors = validateStep2InformationForm(input);
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        const payload = {
          "experience_title":input.experience_title,
          "experience_description":input.experience_description,
          "patient_name":input.patient_name,
          "patient_address":input.patient_address,
          "patient_mobile_number":input.patient_mobile_number,
          "patient_email":input.patient_email,
          "patient_pay_type":input.patient_pay_type,
          "medicalRecordId":medicalRecordId
        };

        axios.post(API_BASE_URL+'medical-record/step2/store',payload,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
        })
        .then(function (response){
          if(response.data.status === true){
            toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
            professionalInformationTab.current.click();
          }else if(response.data.status === false){
            toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
          }
        })
        .catch(function (error){
          toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
      }
    };
  
    const validateStep3Form = (data) => {
      const errors = {};
      
      if (!data.insurance_provider) {
        errors.insurance_provider = 'This field is required';
      }
  
      if (!data.policy_number) {
        errors.policy_number = 'This field is required';
      }
      
      if (!data.network) {
        errors.network = 'This field is required';
      }
  
      if (!data.coverage) {
        errors.coverage = 'This field is required';
      }

      if (!data.allergies) {
        errors.allergies = 'This field is required';
      }

      if (!data.surgeries) {
        errors.surgeries = 'This field is required';
      }

      if (!data.x_rays) {
        errors.x_rays = 'This field is required';
      }
      return errors;
    };
  
    const handleSubmitStep3Info = (e) => {
      e.preventDefault();
  
      if(medicalRecordId === ''){
        toast({ title: 'Please fill step 2 first.',status: 'error',isClosable: true,position: 'top'})
        return false;
      }
  
      const newErrors = validateStep3Form(input);
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        if (Object.keys(newErrors).length === 0) {
          const payload = {
            "insurance_provider":input.insurance_provider,
            "policy_number":input.policy_number,
            "network":input.network,
            "coverage":input.coverage,
            "allergies":input.allergies,
            "surgeries":input.surgeries,
            "x_rays":input.x_rays,
            "medicalRecordId":medicalRecordId
          };
          axios.post(API_BASE_URL+'medical-record/step3/store',payload,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
            }
          })
          .then(function (response){
            if(response.data.status === true){
              toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
              publicationResearchTab.current.click();
            }else if(response.data.status === false){
              toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
            }
          })
          .catch(function (error){
            toast({ title: error,status: 'error',isClosable: true,position: 'top'})
          })
        }
      }
    };
  
    const validateSubmitStep4Form = (data) => {
      const errors = {};
      if (!data.last_vital_weight) {
        errors.last_vital_weight = 'This field is required';
      }

      if (!data.last_vital_height) {
        errors.last_vital_height = 'This field is required';
      }

      if (!data.last_vital_blood_presure) {
        errors.last_vital_blood_presure = 'This field is required';
      }

      if (!data.last_vital_pulse) {
        errors.last_vital_pulse = 'This field is required';
      }
      
      if (!data.lab_report_wbc) {
        errors.lab_report_wbc = 'This field is required';
      }
  
      if (!data.lab_report_neu) {
        errors.lab_report_neu = 'This field is required';
      }
  
      if (!data.lab_report_lym) {
        errors.lab_report_lym = 'This field is required';
      }
  
      if (!data.lab_report_mono) {
        errors.lab_report_mono = 'This field is required';
      }
      return errors;
    };
  
    const handleSubmitStep4Info = (e) => {
      e.preventDefault();
  
      if(medicalRecordId === ''){
        toast({ title: 'Please fill Step 3 details first.',status: 'error',isClosable: true,position: 'top'})
        return false;
      }
  
      const newErrors = validateSubmitStep4Form(input);
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        const payload = {
          "last_vital_weight":input.last_vital_weight,
          "last_vital_height":input.last_vital_height,
          "last_vital_blood_presure":input.last_vital_blood_presure,
          "last_vital_pulse":input.last_vital_pulse,
          "aspirin":input.aspirin,
          "nurofen":input.nurofen,
          "paracetamol":input.paracetamol,
          "lab_report_wbc":input.lab_report_wbc,
          "lab_report_neu":input.lab_report_neu,
          "lab_report_lym":input.lab_report_lym,
          "lab_report_mono":input.lab_report_mono,
          "medicalRecordId":medicalRecordId
        };
        axios.post(API_BASE_URL+'medical-record/step4/store',payload,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
        })
        .then(function (response){
          if(response.data.status === true){
            toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
            accessibilityTab.current.click();
          }else if(response.data.status === false){
            toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
          }
        })
        .catch(function (error){
          toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
      }
    };
  
    const handleSubmitStep5Info = (e) => {
      e.preventDefault();
      navigate("/admin/hospital-management/patient/medical-records/"+id);
    };

    const handleMedicalFileUpload = (acceptedFiles) => {
      if(medicalRecordId === ''){
        toast({ title: 'Please fill Step 4 details first.',status: 'error',isClosable: true,position: 'top'})
        return false;
      }
  
      acceptedFiles.forEach((file) => {
        const formData = new FormData();
        formData.append("medicalFiles",file);
        formData.append("medicalRecordId",medicalRecordId);
        axios.post(API_BASE_URL+'patient/upload/media-record-file',formData,{
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
        })
        .then(function (response){
          if(response.data.status === true){
            setMedicalFiles(response.data.data);
            toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
          }else if(response.data.status === false){
            toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
          }
        })
        .catch((error) => {
          toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
      });
    }

    const handleAddLabel = (event,id) => {
      let { value } = event.target;
      const payload = {
        "label":value,
        "labelId":id,
      };
      axios.post(API_BASE_URL+'medical-record/update/label',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then(function (response){
        if(response.data.status === false){
          toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
        }
      })
      .catch(function (error){
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    };

    const deleteFile = (id) => {
      const payload = {
        "id":id,
      };
      axios.post(API_BASE_URL+'delete/medical-record/file',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then(function (response){
        if(response.data.status === true){
          setMedicalFiles(response.data.data);
          toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
        }else if(response.data.status === false){
          toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
        }
      })
      .catch(function (error){
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    };

    useEffect(() => {
        getMedicalConditions();
    },[])

    const userTab = React.useRef();
    const educationalInformationTab = React.useRef();
    const professionalInformationTab = React.useRef();
    const publicationResearchTab = React.useRef();
    const accessibilityTab = React.useRef();
  
    return (
      <Flex
          direction="column"
          minH="100vh"
          align="center"
          pt={{ sm: '125px', lg: '75px' }}
          position="relative"
        >
          <Box
            h="45vh"
            bgGradient="linear(to-b, brand.400, brand.600)"
            position="absolute"
            w="100%"
            borderRadius="20px"
          />
    
          <Tabs
            variant="unstyled"
            zIndex="0"
            mt={{ base: '60px', md: '165px' }}
            display="flex"
            flexDirection="column"
          >
            <TabList
              display="flex"
              alignItems="center"
              alignSelf="center"
              justifySelf="center"
            >
              <Tab
                _focus={{ border: '0px', boxShadow: 'unset' }}
                ref={userTab}
                w={{ sm: '120px', md: '250px', lg: '300px' }}
                onClick={() =>
                  setActiveBullets({
                    user: true,
                    educationalInformation: false,
                    professionalInformation: false,
                    publicationResearch: false,
                    accessibility: false,
                  })
                }
              >
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  position="relative"
                  _before={{
                    content: "''",
                    width: { sm: '120px', md: '250px', lg: '300px' },
                    height: '3px',
                    bg: activeBullets.user ? 'white' : 'brand.400',
                    left: { sm: '12px', md: '30px' },
                    top: {
                      sm: activeBullets.user ? '6px' : '4px',
                      md: null,
                    },
                    position: 'absolute',
                    bottom: activeBullets.user ? '40px' : '38px',
                    transition: 'all .3s ease',
                  }}
                >
                  <Box
                    zIndex="1"
                    border="2px solid"
                    borderColor={activeBullets.user ? 'white' : 'brand.400'}
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    w="16px"
                    h="16px"
                    mb="8px"
                    borderRadius="50%"
                  />
                  <Text
                    color={activeBullets.user ? 'white' : 'gray.300'}
                    fontWeight={activeBullets.user ? 'bold' : 'normal'}
                    display={{ sm: 'none', md: 'block' }}
                  >
                    Step - 1
                  </Text>
                </Flex>
              </Tab>
  
              <Tab
                _focus={{ border: '0px', boxShadow: 'unset' }}
                ref={educationalInformationTab}
                w={{ sm: '120px', md: '250px', lg: '300px' }}
                onClick={() =>
                  setActiveBullets({
                    user: true,
                    educationalInformation: true,
                    professionalInformation: false,
                    publicationResearch: false,
                    accessibility: false,
                  })
                }
              >
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  position="relative"
                  _before={{
                    content: "''",
                    width: { sm: '120px', md: '250px', lg: '300px' },
                    height: '3px',
                    bg: activeBullets.educationalInformation ? 'white' : 'brand.400',
                    left: { sm: '12px', md: '30px' },
                    top: {
                      sm: activeBullets.educationalInformation ? '6px' : '4px',
                      md: null,
                    },
                    position: 'absolute',
                    bottom: activeBullets.educationalInformation ? '40px' : '38px',
    
                    transition: 'all .3s ease',
                  }}
                >
                  <Box
                    zIndex="1"
                    border="2px solid"
                    borderColor={activeBullets.educationalInformation ? 'white' : 'brand.400'}
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    w="16px"
                    h="16px"
                    mb="8px"
                    borderRadius="50%"
                  />
                  <Text
                    color={activeBullets.educationalInformation ? 'white' : 'gray.300'}
                    fontWeight={activeBullets.educationalInformation ? 'bold' : 'normal'}
                    display={{ sm: 'none', md: 'block' }}
                  >
                    Step - 2
                  </Text>
                </Flex>
              </Tab>
  
              <Tab
                _focus={{ border: '0px', boxShadow: 'unset' }}
                ref={professionalInformationTab}
                w={{ sm: '120px', md: '250px', lg: '300px' }}
                onClick={() =>
                  setActiveBullets({
                    user: true,
                    educationalInformation: true,
                    professionalInformation: true,
                    publicationResearch: false,
                    accessibility: false,
                  })
                }
              >
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  position="relative"
                  _before={{
                    content: "''",
                    width: { sm: '120px', md: '250px', lg: '300px' },
                    height: '3px',
                    bg: activeBullets.professionalInformation ? 'white' : 'brand.400',
                    left: { sm: '12px', md: '30px' },
                    top: {
                      sm: activeBullets.professionalInformation ? '6px' : '4px',
                      md: null,
                    },
                    position: 'absolute',
                    bottom: activeBullets.professionalInformation ? '40px' : '38px',
                    transition: 'all .3s ease',
                  }}
                >
                  <Box
                    zIndex="1"
                    border="2px solid"
                    borderColor={activeBullets.professionalInformation ? 'white' : 'brand.400'}
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    w="16px"
                    h="16px"
                    mb="8px"
                    borderRadius="50%"
                  />
                  <Text
                    color={activeBullets.professionalInformation ? 'white' : 'gray.300'}
                    fontWeight={activeBullets.professionalInformation ? 'bold' : 'normal'}
                    display={{ sm: 'none', md: 'block' }}
                  >
                    Step - 3
                  </Text>
                </Flex>
              </Tab>
  
              <Tab
                _focus={{ border: '0px', boxShadow: 'unset' }}
                ref={publicationResearchTab}
                w={{ sm: '120px', md: '250px', lg: '300px' }}
                onClick={() =>
                  setActiveBullets({
                    user: true,
                    educationalInformation: true,
                    professionalInformation: true,
                    publicationResearch: true,
                    accessibility: false,
                  })
                }
              >
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  position="relative"
                  _before={{
                    content: "''",
                    width: { sm: '120px', md: '250px', lg: '300px' },
                    height: '3px',
                    bg: activeBullets.publicationResearch ? 'white' : 'brand.400',
                    left: { sm: '12px', md: '30px' },
                    top: {
                      sm: activeBullets.publicationResearch ? '6px' : '4px',
                      md: null,
                    },
                    position: 'absolute',
                    bottom: activeBullets.publicationResearch ? '40px' : '38px',
    
                    transition: 'all .3s ease',
                  }}
                >
                  <Box
                    zIndex="1"
                    border="2px solid"
                    borderColor={activeBullets.publicationResearch ? 'white' : 'brand.400'}
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    w="16px"
                    h="16px"
                    mb="8px"
                    borderRadius="50%"
                  />
                  <Text
                    color={activeBullets.publicationResearch ? 'white' : 'gray.300'}
                    fontWeight={activeBullets.publicationResearch ? 'bold' : 'normal'}
                    display={{ sm: 'none', md: 'block' }}
                  >
                    Step - 4
                  </Text>
                </Flex>
              </Tab>
  
              <Tab
                _focus={{ border: '0px', boxShadow: 'unset' }}
                ref={accessibilityTab}
                w={{ sm: '120px', md: '250px', lg: '300px' }}
                onClick={() =>
                  setActiveBullets({
                    user: true,
                    educationalInformation: true,
                    professionalInformation: true,
                    publicationResearch: true,
                    accessibility: true,
                  })
                }
              >
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  position="relative"
                  _before={{
                    content: "''",
                    width: { sm: '120px', md: '250px', lg: '300px' },
                    height: '3px',
                    bg: activeBullets.accessibility ? 'white' : 'brand.400',
                    left: { sm: '12px', md: '32px' },
                    top: '6px',
                    position: 'absolute',
                    bottom: activeBullets.accessibility ? '40px' : '38px',
    
                    transition: 'all .3s ease',
                  }}
                >
                  <Box
                    zIndex="1"
                    border="2px solid"
                    borderColor={activeBullets.accessibility ? 'white' : 'brand.400'}
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    w="16px"
                    h="16px"
                    mb="8px"
                    borderRadius="50%"
                  />
                  <Text
                    color={activeBullets.accessibility ? 'white' : 'gray.300'}
                    fontWeight={activeBullets.accessibility ? 'bold' : 'normal'}
                    display={{ sm: 'none', md: 'block' }}
                  >
                    Step - 5
                  </Text>
                </Flex>
              </Tab>
  
            </TabList>
  
            <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
              <TabPanel
                w={{ sm: '330px', md: '700px', lg: '850px' }}
                p="0px"
                mx="auto"
              >
                <Card p="30px">
                  <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                    Step 1
                  </Text>
                  <Flex direction="column" w="100%">
                    <Stack direction="column" spacing="20px">
                      <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">

                        <Flex direction="column">
                          <InputField
                              mb="0px"
                              id="hospital_name"
                              placeholder="eg. Hospital Name"
                              label="Hospital Name"
                              name="hospital_name"
                              onChange={handleChange}
                              value={input.hospital_name}
                          />
                          {errors.hospital_name &&
                            <span className="error-message">
                                {errors.hospital_name}
                            </span>
                          }
                        </Flex>

                        <Flex direction="column">
                          <InputField
                              mb="0px"
                              id="patient_id"
                              placeholder="eg. PID123456789"
                              label="Patient Id"
                              name="patient_id"
                              onChange={handleChange}
                              value={id}
                          />
                        </Flex>

                        <Flex direction="column">
                          <InputField
                              mb="0px"
                              id="blood_group"
                              placeholder="eg. A+"
                              label="Blood Group"
                              name="blood_group"
                              onChange={handleChange}
                              value={input.blood_group}
                          />
                          {errors.blood_group &&
                            <span className="error-message">
                                {errors.blood_group}
                            </span>
                          }
                        </Flex>

                        <Flex direction="column">
                          <InputField
                              mb="0px"
                              id="doctor"
                              placeholder="eg. Doctor"
                              label="Doctor"
                              name="doctor"
                              onChange={handleChange}
                              value={input.doctor}
                          />
                          {errors.doctor &&
                            <span className="error-message">
                                {errors.doctor}
                            </span>
                          }
                        </Flex>

                        <Flex direction="column">
                          <FormLabel
                            ms="10px"
                            htmlFor="gender"
                            fontSize="sm"
                            color=''
                            fontWeight="bold"
                            _hover={{ cursor: 'pointer' }}
                          >
                            Gender
                          </FormLabel>

                          <RadioGroup onChange={handleGenderChange} value={gender} name='gender'>
                            <Stack direction='row'>
                              <Radio value='1'>Male</Radio>
                              <Radio value='2'>Female</Radio>
                            </Stack>
                          </RadioGroup>
                          {errors.gender &&
                            <span className="error-message">
                                {errors.gender}
                            </span>
                          }
                        </Flex>

                        <Flex direction="column">
                          <InputField
                              mb="0px"
                              id="weight"
                              placeholder="74 kg"
                              label="Weigth in kg"
                              name="weight"
                              onChange={handleChange}
                              value={input.weight}
                          />
                          {errors.weight &&
                            <span className="error-message">
                                {errors.weight}
                            </span>
                          }
                        </Flex>

                        <Flex direction="column">
                          <InputField
                              mb="0px"
                              id="height"
                              placeholder="ONLINE"
                              label="Height in cm"
                              name="height"
                              onChange={handleChange}
                              value={input.height}
                          />
                          {errors.height &&
                            <span className="error-message">
                                {errors.height}
                            </span>
                          }
                        </Flex>

                        <Flex direction="column">
                            <FormLabel
                            ms="10px"
                            htmlFor="medical-conditions"
                            fontSize="sm"
                            color=''
                            fontWeight="bold"
                            _hover={{ cursor: 'pointer' }}
                            >
                            Do you have any previous medical conditions ?
                            </FormLabel>

                            <Multiselect
                                options={medicalConditions} // Options to display in the dropdown
                                onSelect={handleMedicalConditionsSelectValue} // Function will trigger on select event
                                onRemove={handleMedicalConditionsSelectValue} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                borderRadius="16px"
                            />

                            {errors.medical_conditions_ids &&
                            <span className="error-message">
                                {errors.medical_conditions_ids}
                            </span>
                            }
                        </Flex>
                      </SimpleGrid>
                    </Stack>

                    <Flex justify="space-between" mt="24px">
                      <Button
                        variant="darkBrand"
                        fontSize="sm"
                        borderRadius="16px"
                        w={{ base: '128px', md: '148px' }}
                        h="46px"
                        ms="auto"
                        type='submit'
                        onClick={handleSubmitStep1Info}
                      >
                        Next
                      </Button>
                    </Flex>
                  </Flex>
                </Card>
              </TabPanel>
  
              <TabPanel
                w={{ sm: '330px', md: '700px', lg: '850px' }}
                p="0px"
                mx="auto"
              >
                <Card p="30px">
                  <Text color={textColor} fontSize="2xl" fontWeight="700" mb="10px">Your experience</Text>
                  <Text margin="0 0 10px 0">Share your experience with the hospital</Text>

                    <Flex direction="column" w="100%">
                        <Stack direction="column" spacing="20px" margin="20px 0px">
                          <Flex direction="column">
                            <InputField
                                mb="0px"
                                id="experience_title"
                                placeholder="eg. About Aster DM Healthcare"
                                label="Title"
                                name="experience_title"
                                onChange={handleChange}
                                value={input.experience_title}
                            />
                            {errors.experience_title &&
                              <span className="error-message">
                                  {errors.experience_title}
                              </span>
                            }
                          </Flex>

                          <Flex direction="column">
                            <TextField
                              minH="100px"
                              id="description"
                              placeholder="Enter a few words about property"
                              label="Description Content"
                              name="experience_description"
                              onChange={handleChange}
                              value={input.experience_description}
                            />
                            {errors.experience_description &&
                              <span className="error-message">
                                  {errors.experience_description}
                              </span>
                            }
                          </Flex>
                        </Stack>

                        <Text color={textColor} fontSize="2xl" fontWeight="700" mb="10px">Personal Information</Text>
                        <Text margin="0 0 10px 0">Enter your personal information here</Text>
  
                        <Stack direction="column" spacing="20px">
                          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">

                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="patient-name"
                                  placeholder="eg. Jimin Gabriel"
                                  label="Name"
                                  name="patient_name"
                                  onChange={handleChange}
                                  value={input.patient_name}
                              />
                              {errors.patient_name &&
                                <span className="error-message">
                                    {errors.patient_name}
                                </span>
                              }
                            </Flex>
  
                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="patient-address"
                                  placeholder="eg. Business Bay, Dubai, UAE"
                                  label="Address"
                                  name="patient_address"
                                  onChange={handleChange}
                                  value={input.patient_address}
                              />
                              {errors.patient_address &&
                                <span className="error-message">
                                    {errors.patient_address}
                                </span>
                              }
                            </Flex>

                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="patient-mobile-number"
                                  placeholder="eg. Mobile Number"
                                  label="Address"
                                  name="patient_mobile_number"
                                  onChange={handleChange}
                                  value={input.patient_mobile_number}
                              />
                              {errors.patient_mobile_number &&
                                <span className="error-message">
                                    {errors.patient_mobile_number}
                                </span>
                              }
                            </Flex>

                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="patient-mobile-number"
                                  placeholder="eg. E-mail"
                                  label="E-mail"
                                  name="patient_email"
                                  onChange={handleChange}
                                  value={input.patient_email}
                              />
                              {errors.patient_email &&
                                <span className="error-message">
                                    {errors.patient_email}
                                </span>
                              }
                            </Flex>

                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="patient-pay-type"
                                  placeholder="eg. Online"
                                  label="Pay Type"
                                  name="patient_pay_type"
                                  onChange={handleChange}
                                  value={input.patient_pay_type}
                              />
                              {errors.patient_pay_type &&
                                <span className="error-message">
                                    {errors.patient_pay_type}
                                </span>
                              }
                            </Flex>
                          </SimpleGrid>
                        </Stack>
                        <Flex justify="space-between" mt="24px">
                          <Button
                            variant="darkBrand"
                            fontSize="sm"
                            borderRadius="16px"
                            w={{ base: '128px', md: '148px' }}
                            h="46px"
                            ms="auto"
                            type='submit'
                            onClick={handleSubmitStep2Info}
                          >
                            Next
                          </Button>
                        </Flex>
                    </Flex>
                </Card>
              </TabPanel>
  
              <TabPanel
                w={{ sm: '330px', md: '700px', lg: '850px' }}
                p="0px"
                mx="auto"
              >
                <Card p="30px">
                  <Text color={textColor} fontSize="2xl" fontWeight="700" mb="10px">Insurance Information</Text>
                  <Text margin="0 0 10px 0">Please type your insurance information</Text>
                    <Flex direction="column" w="100%">

                      <Stack direction="column" spacing="20px">
                        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                          <Flex direction="column">
                            <InputField
                                mb="0px"
                                id="insurance_provider"
                                placeholder="eg. Abu Dhabi national Insurance"
                                label="Insurance Provider"
                                name="insurance_provider"
                                onChange={handleChange}
                                value={input.insurance_provider}
                            />
                            {errors.insurance_provider &&
                              <span className="error-message">
                                  {errors.insurance_provider}
                              </span>
                            }
                          </Flex>

                          <Flex direction="column">
                            <InputField
                              id="policy_number"
                              placeholder="eg. 0123456789"
                              label="Policy Number"
                              name="policy_number"
                              onChange={handleChange}
                              value={input.policy_number}
                            />
                            {errors.policy_number &&
                              <span className="error-message">
                                  {errors.policy_number}
                              </span>
                            }
                          </Flex>

                          <Flex direction="column">
                            <InputField
                              id="network"
                              placeholder="eg. Health Insurance Coverage"
                              label="Network"
                              name="network"
                              onChange={handleChange}
                              value={input.network}
                            />
                            {errors.network &&
                              <span className="error-message">
                                  {errors.network}
                              </span>
                            }
                          </Flex>

                          <Flex direction="column">
                            <InputField
                              id="coverage"
                              placeholder="eg. Health Insurance Coverage"
                              label="Coverage"
                              name="coverage"
                              onChange={handleChange}
                              value={input.coverage}
                            />
                            {errors.coverage &&
                              <span className="error-message">
                                  {errors.coverage}
                              </span>
                            }
                          </Flex>
                        </SimpleGrid>
                      </Stack>

                      <Text color={textColor} fontSize="2xl" fontWeight="700" mb="10px">Medical History</Text>
                      <Text margin="0 0 10px 0">Please type your history record</Text>

                      <Stack direction="column" spacing="20px">
                        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="allergies"
                                  placeholder="eg. Food Allergies"
                                  label="Allergies"
                                  name="allergies"
                                  onChange={handleChange}
                                  value={input.allergies}
                              />
                              {errors.allergies &&
                                <span className="error-message">
                                    {errors.allergies}
                                </span>
                              }
                            </Flex>

                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="surgeries"
                                  placeholder="eg. Urological Surgery"
                                  label="Surgeries"
                                  name="surgeries"
                                  onChange={handleChange}
                                  value={input.surgeries}
                              />
                              {errors.surgeries &&
                                <span className="error-message">
                                    {errors.surgeries}
                                </span>
                              }
                            </Flex>


                            <Flex direction="column">
                              <InputField
                                  mb="0px"
                                  id="x-rays"
                                  placeholder="eg. Health Insurance Coverage"
                                  label="X-rays"
                                  name="x_rays"
                                  onChange={handleChange}
                                  value={input.x_rays}
                              />
                              {errors.x_rays &&
                                <span className="error-message">
                                    {errors.x_rays}
                                </span>
                              }
                            </Flex>
                        </SimpleGrid>
                      </Stack>
  
                      <Flex justify="space-between" mt="24px">
                        <Button
                          variant="darkBrand"
                          fontSize="sm"
                          borderRadius="16px"
                          w={{ base: '128px', md: '148px' }}
                          h="46px"
                          ms="auto"
                          type='submit'
                          onClick={handleSubmitStep3Info}
                        >
                          Next
                        </Button>
                      </Flex>
                    </Flex>
                </Card>
              </TabPanel>
  
              <TabPanel
                w={{ sm: '330px', md: '700px', lg: '850px' }}
                p="0px"
                mx="auto"
              >
                <Card p="30px">
                  <Text color={textColor} fontSize="2xl" fontWeight="700" mb="10px">Last Vitals</Text>
                  <Text margin="0 0 10px 0">Please write your last vitals here</Text>
                  <Flex direction="column" w="100%">
                      <Stack direction="column" spacing="20px" margin="0 0 30px 0">
                        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                          <Flex direction="column">
                            <InputField
                                mb="0px"
                                id="last_vital_weight"
                                placeholder="eg. 74 KG"
                                label="Weight"
                                name="last_vital_weight"
                                onChange={handleChange}
                                value={input.last_vital_weight}
                            />
                            {errors.last_vital_weight &&
                              <span className="error-message">
                                  {errors.last_vital_weight}
                              </span>
                            }
                          </Flex>

                          <Flex direction="column">
                            <InputField
                                mb="0px"
                                id="last_vital_height"
                                placeholder="eg. 170 CM"
                                label="Height"
                                name="last_vital_height"
                                onChange={handleChange}
                                value={input.last_vital_height}
                            />
                            {errors.last_vital_height &&
                              <span className="error-message">
                                  {errors.last_vital_height}
                              </span>
                            }
                          </Flex>

                          <Flex direction="column">
                            <InputField
                                mb="0px"
                                id="last_vital_blood_presure"
                                placeholder="eg. 80/120"
                                label="Blood Presure"
                                name="last_vital_blood_presure"
                                onChange={handleChange}
                                value={input.last_vital_blood_presure}
                            />
                            {errors.last_vital_blood_presure &&
                              <span className="error-message">
                                  {errors.last_vital_blood_presure}
                              </span>
                            }
                          </Flex>

                          <Flex direction="column">
                            <InputField
                                mb="0px"
                                id="last_vital_pulse"
                                placeholder="eg. 80/120"
                                label="Pulse"
                                name="last_vital_pulse"
                                onChange={handleChange}
                                value={input.last_vital_pulse}
                            />
                            {errors.last_vital_pulse &&
                              <span className="error-message">
                                  {errors.last_vital_pulse}
                              </span>
                            }
                          </Flex>
                        </SimpleGrid>
                      </Stack>

                      <Text color={textColor} fontSize="2xl" fontWeight="700" mb="10px">Chronic Medication</Text>
                      <Text margin="0 0 10px 0">Write the name of the medicine you are taking daily for a long time</Text>

                      <Stack direction="column" spacing="20px" margin="0 0 30px 0">
                        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                          <Flex direction="column">
                            <label for="aspirin" class="chakra-form__label css-186ooiv">Aspirin<p class="chakra-text css-72mia8"></p></label>
                            <input
                                type='date'
                                mb="0px"
                                id="aspirin"
                                name="aspirin"
                                onChange={handleChange}
                                value={input.aspirin}
                                class="chakra-input css-1t1l5ix"
                            />
                            {/* {errors.year_of_graduation &&
                              <span className="error-message">
                                  {errors.year_of_graduation}
                              </span>
                            } */}
                          </Flex>

                          <Flex direction="column">
                            <label for="nurofen" class="chakra-form__label css-186ooiv">Nurofen<p class="chakra-text css-72mia8"></p></label>
                            <input
                                type='date'
                                mb="0px"
                                id="nurofen"
                                name="nurofen"
                                onChange={handleChange}
                                value={input.nurofen}
                                class="chakra-input css-1t1l5ix"
                            />
                          </Flex>

                          <Flex direction="column">
                            <label for="paracetamol" class="chakra-form__label css-186ooiv">Paracetamol<p class="chakra-text css-72mia8"></p></label>
                            <input
                                type='date'
                                mb="0px"
                                id="paracetamol"
                                name="paracetamol"
                                onChange={handleChange}
                                value={input.paracetamol}
                                class="chakra-input css-1t1l5ix"
                            />
                          </Flex>
                        </SimpleGrid>
                      </Stack>

                      <Text color={textColor} fontSize="2xl" fontWeight="700" mb="10px">Lab Reports</Text>
                      <Text margin="0 0 10px 0">Provide information about your latest lab reports.</Text>

                      <Stack direction="column" spacing="20px">
                        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                          <Flex direction="column">
                            <InputField
                                mb="0px"
                                id="lab_report_wbc"
                                placeholder="eg. 72 K/PL"
                                label="WBC"
                                name="lab_report_wbc"
                                onChange={handleChange}
                                value={input.lab_report_wbc}
                            />
                            {errors.lab_report_wbc &&
                              <span className="error-message">
                                  {errors.lab_report_wbc}
                              </span>
                            }
                          </Flex>

                          <Flex direction="column">
                            <InputField
                                mb="0px"
                                id="lab_report_neu"
                                placeholder="eg. 24.45 K/PL"
                                label="NEU"
                                name="lab_report_neu"
                                onChange={handleChange}
                                value={input.lab_report_neu}
                            />
                            {errors.lab_report_neu &&
                              <span className="error-message">
                                  {errors.lab_report_neu}
                              </span>
                            }
                          </Flex>

                          <Flex direction="column">
                            <InputField
                                mb="0px"
                                id="lab_report_lym"
                                placeholder="eg. 6 K/PL"
                                label="LYM"
                                name="lab_report_lym"
                                onChange={handleChange}
                                value={input.lab_report_lym}
                            />
                            {errors.lab_report_lym &&
                              <span className="error-message">
                                  {errors.lab_report_lym}
                              </span>
                            }
                          </Flex>

                          <Flex direction="column">
                            <InputField
                                mb="0px"
                                id="lab_report_mono"
                                placeholder="eg. 1.12 K/PL"
                                label="MONO"
                                name="lab_report_mono"
                                onChange={handleChange}
                                value={input.lab_report_mono}
                            />
                            {errors.lab_report_mono &&
                              <span className="error-message">
                                  {errors.lab_report_mono}
                              </span>
                            }
                          </Flex>
                        </SimpleGrid>
                      </Stack>

                      <Flex justify="space-between" mt="24px">
                        <Button
                          variant="darkBrand"
                          fontSize="sm"
                          borderRadius="16px"
                          w={{ base: '128px', md: '148px' }}
                          h="46px"
                          ms="auto"
                          type='submit'
                          onClick={handleSubmitStep4Info}
                        >
                          Next
                        </Button>
                      </Flex>
                  </Flex>
                </Card>
              </TabPanel>
  
              <TabPanel
                w={{ sm: '330px', md: '700px', lg: '850px' }}
                p="0px"
                mx="auto"
              >
                <Card p="30px">
                  <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                    All Visits
                  </Text>

                  <Flex direction="column" w="100%">
                    <FormLabel
                      ms="10px"
                      htmlFor="media_images"
                      fontSize="sm"
                      color=''
                      fontWeight="bold"
                      _hover={{ cursor: 'pointer' }}
                    >
                      Media Images
                    </FormLabel> 

                    <Dropzone onDrop={handleMedicalFileUpload} accept="image/*">
                      {({getRootProps, getInputProps, isDragActive, isDragAccept,isDragReject }) => {
                        const additionalClass = isDragAccept ? "accept" :isDragReject ? "reject" : "";  
                        return (
                          <div
                          {...getRootProps({
                            className: `dropzone ${additionalClass}`
                          })}
                          >
                            <input {...getInputProps()} />
                            <p>Drag'n'drop images,or click to select files</p>
                          </div>
                        );
                      }}
                    </Dropzone>
                    {medicalFiles.map((item,index) => (
                      <>
                        <div className="input_container" key={index}>
                          <div className='medical-file-preview'>
                            <img src={ASSETS_URL + 'users/medical-records/original/' + item.file} className={`img-container logo-image medical-file-preview-image logo-image-${index}`} alt='upload cover file' width="50%" height="100px" />
                          </div>
                          <input name="filename" type="text" className='css-1t1l5ix medical-file-name' onChange={(event) => handleAddLabel(event,item.id)} value={item.label}/>
                          <div className='button-row'>
                            <button className='delete-row' onClick={() => deleteFile(item.id)}>Delete</button>
                          </div>
                        </div>
                      </>
                    ))}
                  </Flex>
  
                  <Flex direction="column" w="100%">
                    <Stack direction="column" spacing="20px" margin="0 0 30px 0">
                      <SimpleGrid columns={{ base: 22, md: 2 }} gap="20px">
                      </SimpleGrid>
                    </Stack>
                    <Flex justify="space-between" mt="24px">
                      <Button
                        variant="darkBrand"
                        fontSize="sm"
                        borderRadius="16px"
                        w={{ base: '128px', md: '148px' }}
                        h="46px"
                        ms="auto"
                        type='submit'
                        onClick={handleSubmitStep5Info}
                      >
                        Next
                      </Button>
                    </Flex>
                  </Flex>
                </Card>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
    );
  }
  